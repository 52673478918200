import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, DeleteButton, Form, FormField, Loader } from 'components'
import { useParams } from 'react-router'
import { useSdk } from 'sdk'
import {
  formatPhone,
  formatPriceNumberWithDelimiter,
  getGiftCardStatusOptions,
  roundNumberWithTwoDecimals,
  serialize,
} from 'src/helpers'
import { t } from 'src/localization'
import { RouterOutput, trpc } from 'src/trpc'
import { FormContainer } from 'src/styles'
import * as Yup from 'yup'
import {
  FormikComputedProps,
  FormikHandlers,
  FormikHelpers,
  FormikProps,
  FormikRegistration,
  FormikSharedConfig,
  FormikState,
} from 'formik'
import { FormFieldsWrapper } from 'src/components/Form/Form'
import {
  FieldRow,
  FieldColumn,
  FormFooterButtonsContainer,
  FormPageContainer,
} from 'src/sdk/tw/Form'
import { MobileContext } from 'src/Context'
import { ClientSelect } from 'src/sdk/tw/components/ClientSelect'
import { ClientFormWrapper } from 'src/views/AppointmentModal/components/ClientFormWrapper'
import { useValidationSchema } from 'src/sdk/useValidationSchema'
import { useACL } from 'src/sdk/acl'
import { useClientsAddMutation } from 'src/state/graphql'
import { searchClients, useInviteUser } from 'src/state/queries'
import moment from 'moment'
import { ButtonTW } from 'src/sdk/tw/ButtonTW'
import Tooltip from 'src/components/Tooltip/Tooltip'
import { ServicesTab } from './GiftCardScreen'

const validationSchema = t =>
  Yup.object().shape({
    name: Yup.string().required(
      t('translation.NewResource.validation-name-required')
    ),
    giftCardTemplateId: Yup.string().required('Predložak je obavezan podatak'),
    status: Yup.string().required('Status je obavezan podatak'),
  })

type GiftCardFormProps = {
  onSubmit: (values: any) => void
  initialValues: any
  loading: boolean
  isEdit?: boolean
  refetch?: any
  currency: string
  language: string
  giftCardInitialClientId?: string | null
  isGiftCardUsed: boolean
  setIsPreviewMode?: any
}

export const GiftCardForm = (props: GiftCardFormProps) => {
  const {
    onSubmit,
    initialValues,
    isEdit,
    loading,
    refetch,
    currency,
    language,
    giftCardInitialClientId,
    isGiftCardUsed,
    setIsPreviewMode,
  } = props
  const params = useParams<{
    locationId: string
    orgId: string
    id: string
    type: string
  }>()

  const { locationId, orgId, id, type } = params
  let formRef = useRef<FormikProps<any>>()
  const isMobile = useContext(MobileContext)
  const { navigateTo, appServices } = useSdk()
  const [activeForm, setActiveForm] = useState<'giftcard' | 'client'>(
    'giftcard'
  )
  const { ClientSchema } = useValidationSchema()

  const { hasPermission } = useACL()
  const loyaltyType = isEdit ? initialValues?.voucherType.toLowerCase() : type

  const deleteGiftCardMutation = trpc.giftCard_delete.useMutation()
  const trpcContext = trpc.useContext()

  const resendClientEmailMutation =
    trpc.giftCard_resendClientEmail.useMutation()

  const { data, isLoading } = trpc.giftCardTemplate_findMany.useQuery({
    orgId: orgId,
    locationId: BigInt(locationId),
    voucherType: [loyaltyType],
    searchTerm: '',
  })

  const dataList = data as [RouterOutput['giftCardTemplate_findOne']] | []
  useEffect(() => {
    trpcContext.giftCardTemplate_findMany.invalidate({
      orgId,
      locationId: BigInt(locationId),
      voucherType: loyaltyType,
      searchTerm: '',
    })
  }, [loyaltyType])

  const handleDeleteGiftCard = async () => {
    try {
      const res = await deleteGiftCardMutation.mutateAsync({
        id: BigInt(id),
      })
      if (res) {
        appServices.toast.success(
          loyaltyType === 'giftcard'
            ? 'Poklon bon je uspješno obrisan'
            : 'Paket je uspješno obrisan'
        )
        navigateTo.listGiftCard(params)
      } else
        appServices.toast.danger(
          loyaltyType === 'giftcard'
            ? 'Poklon bon nije uspješno obrisan'
            : 'Paket nije uspješno obrisan'
        )
    } catch (e) {
      appServices.toast.danger(
        loyaltyType === 'giftcard'
          ? 'Poklon bon nije uspješno obrisan'
          : 'Paket nije uspješno obrisan'
      )
    }
  }

  const handleResendClientEmail = async () => {
    try {
      const res = await resendClientEmailMutation.mutateAsync({
        giftCardId: BigInt(id),
        locationId: BigInt(locationId),
        orgId,
      })

      appServices.toast.success('Email je uspješno poslan')
    } catch (e) {
      appServices.toast.danger('Email nije uspješno poslan')
    }
  }

  const handleValueChange = (name, value, values, form) => {
    if (name === 'giftCardTemplateId') {
      const selectedTemplate = dataList?.find(
        template => template?.id === value?.id
      )

      form.setFieldValue('name', selectedTemplate?.name)
      form.setFieldValue('terms', selectedTemplate?.voucherTerms)
      form.setFieldValue('description', selectedTemplate?.voucherDesc)
      if (!values.validTo && values?.status?.id?.toLowerCase() === 'active') {
        form.setFieldValue(
          'validTo',
          moment()
            .add(
              selectedTemplate?.validAmount as any,
              selectedTemplate?.validIterator as any
            )
            .format('YYYY-MM-DD')
        )
      }
    }

    if (name === 'clientId') {
      if (value) {
        form.setFieldValue('status', {
          id: 'ACTIVE',
          name: t('translation.GiftCardList.status-single-active'),
        })
        if (values?.giftCardTemplateId) {
          const selectedTemplate = dataList?.find(
            template => template?.id === values?.giftCardTemplateId?.id
          )

          if (!values.validTo) {
            form.setFieldValue(
              'validTo',
              moment()
                .add(
                  selectedTemplate?.validAmount as any,
                  selectedTemplate?.validIterator as any
                )
                .format('YYYY-MM-DD')
            )
          }
        }
      } else {
        if (
          isEdit &&
          !isGiftCardUsed &&
          initialValues?.status?.id?.toLowerCase() === 'active' &&
          !initialValues?.isSold
        ) {
          form.setFieldValue('status', {
            id: 'INACTIVE',
            name: t('translation.GiftCardList.status-single-inactive'),
          })
        }
      }
    }
    if (
      name === 'status' &&
      value?.id?.toLowerCase() === 'active' &&
      values?.giftCardTemplateId
    ) {
      const selectedTemplate = dataList?.find(
        template => template?.id === values?.giftCardTemplateId?.id
      )

      if (!values.validTo) {
        form.setFieldValue(
          'validTo',
          moment()
            .add(
              selectedTemplate?.validAmount as any,
              selectedTemplate?.validIterator as any
            )
            .format('YYYY-MM-DD')
        )
      }
    }
  }

  function getOnNewClient(
    form: FormikSharedConfig<{}> &
      FormikState<any> &
      FormikHelpers<any> &
      FormikHandlers &
      FormikComputedProps<any> &
      FormikRegistration & { submitForm: () => Promise<any> }
  ) {
    return () => {
      form.setFieldValue('client', null)
      setActiveForm('client')
      form.setFieldValue('firstName', '')
      form.setFieldValue('lastName', '')
      form.setFieldValue('email', '')
      form.setFieldValue('mobilePhone', '')
      form.setFieldValue('address', '')
      form.setFieldValue('zipCode', '')
      form.setFieldValue('city', '')
      form.setFieldValue('gender', '')
      form.setFieldValue('comment', '')
      form.setFieldValue('paymentRequired', true)
    }
  }
  const [clientAddMutation, { loading: loadingAddClient }] =
    useClientsAddMutation()

  const [inviteUser] = useInviteUser()

  async function createClient(values) {
    const clientResult = await clientAddMutation({
      variables: {
        input: {
          firstName: values.firstName?.trim(),
          lastName: values.lastName?.trim() || '',
          fullName:
            values.firstName?.trim() + ' ' + (values.lastName?.trim() || ''),
          email: values.email ? values.email?.trim() : '',
          countryId: values.country?.id,
          address: values.address,
          zipCode: values.zipCode,
          language: language || 'hr',
          city: values.city,
          comment: values.comment,
          mobilePhone: formatPhone(values.mobilePhone),
          gender: values.gender,
          identifier: values.identifier,
          vatId: values.vatId,
          birthDate: values.birthDate
            ? moment(values.birthDate).format('YYYY-MM-DD')
            : null,
          sendReminderEmail: values?.sendReminderEmail,
          sendReminderSms: values?.sendReminderSms,
          sendBookingEmail: true,
          sendNewsEmail: true,
          sendHelpEmail: true,
          paymentRequired: values?.paymentRequired,
        },
      },
      refetchQueries: [
        {
          query: searchClients,
          variables: {
            pageNumber: 1,
            pageSize: 50,
            searchTerm: '',
            sortBy: localStorage.getItem('clientsSortBy') || 'id_desc',
          },
        },
      ],
    })
    if (values?.sendInviteViaEMail) {
      await inviteUser({
        variables: {
          input: {
            firstName: values.firstName?.trim(),
            lastName: values.lastName?.trim() || '',
            email: values.email,
            gender: values.gender || 'Unspecified',
            mobilePhone: formatPhone(values.mobilePhone),
            role: 'CLIENT',
            clientId: clientResult?.data?.clients?.add?.payload?.id!,
          },
        },
      })
    }

    if (!clientResult?.errors) {
      // @ts-ignore
      appServices.toast.success(
        (t('translation.AppointmentModal.toast-newClientEntered'),
        {
          firstName: values.firstName,
          lastName: values.lastName || '',
        })
      )
      return clientResult?.data?.clients?.add?.payload
    } else {
      appServices.toast.danger(
        t('translation.AppointmentModal.toast-newClientError')
      )
    }
  }

  const onSubmitClient = async values => {
    try {
      const client = await createClient(values)
      if (client) {
        formRef.current?.setFieldValue('clientId', client?.id)
        formRef.current?.setFieldValue('client', client)
        setActiveForm('giftcard')
      }
    } catch (e) {
      appServices.toast.danger('Klijent nije uspješno kreiran')
    }
  }

  return (
    <>
      <FormPageContainer>
        {loading ? <Loader isComponent /> : null}
        <Form
          onSubmit={activeForm === 'giftcard' ? onSubmit : onSubmitClient}
          initialValues={{
            ...initialValues,
          }}
          onValueChange={handleValueChange}
          validationSchema={
            activeForm === 'giftcard' ? validationSchema(t) : ClientSchema
          }
        >
          {form => {
            formRef.current = form
            //console.log(form.values)
            return activeForm === 'giftcard' ? (
              <FormFieldsWrapper>
                <FormContainer>
                  <FieldRow>
                    <FieldColumn className="lg:items-center lg:flex-row flex-col">
                      <p className="lg:w-[150px] w-full mr-[14px] font-semibold">
                        Vrsta predloška
                      </p>
                      <FormField.Select
                        closeMenuOnSelect={true}
                        isClearable={false}
                        isDisabled={isEdit}
                        name="giftCardTemplateId"
                        options={dataList
                          ?.sort((a, b) => Number(b?.id) - Number(a?.id))
                          ?.map(template => {
                            return {
                              id: template?.id,
                              name: template?.name,
                            }
                          })}
                      />
                    </FieldColumn>
                    <FieldColumn className="lg:justify-end lg:items-center lg:flex-row flex-col">
                      <span className="mr-[10px] font-semibold">Status</span>
                      <div>
                        <FormField.Select
                          closeMenuOnSelect={true}
                          name="status"
                          isClearable={false}
                          options={getGiftCardStatusOptions(
                            isGiftCardUsed,
                            initialValues?.status?.id
                          )}
                          selectMinWidth={'150px'}
                        />
                      </div>
                    </FieldColumn>
                  </FieldRow>

                  <FieldRow>
                    <FieldColumn className="max-w-[150px] font-semibold">
                      <span>Naziv</span>
                    </FieldColumn>
                    <FieldColumn style={{ flexDirection: 'column' }}>
                      <FormField.Text
                        //forceAutoFocus
                        disabled={
                          isEdit
                            ? initialValues?.status?.id === 'active' ||
                              initialValues?.status?.id === 'expired'
                            : false
                        }
                        autoCapitalize="on"
                        name="name"
                      />
                    </FieldColumn>
                  </FieldRow>
                  {isEdit ? (
                    <>
                      <FieldRow>
                        <FieldColumn className="max-w-[150px] font-semibold">
                          <span>Oznaka</span>
                        </FieldColumn>
                        <FieldColumn style={{ flexDirection: 'column' }}>
                          <FormField.Text readOnly disabled name="code" />
                        </FieldColumn>
                      </FieldRow>
                    </>
                  ) : null}

                  <FieldRow>
                    <FieldColumn className="max-w-[150px] font-semibold">
                      <span>Vrijedi do</span>
                    </FieldColumn>
                    <FieldColumn style={{ flexDirection: 'column' }}>
                      <FormField.DateInput
                        name="validTo"
                        displayFormat={'DD.MM.YYYY.'}
                      />
                    </FieldColumn>
                  </FieldRow>

                  <FieldRow>
                    <FieldColumn className="max-w-[150px] font-semibold">
                      <span>Klijent</span>
                    </FieldColumn>
                    <FieldColumn style={{ flexDirection: 'column' }}>
                      <ClientSelect
                        showAddNewButton={hasPermission('ACCESS_CLIENT_LIST')}
                        onNewClient={getOnNewClient(form)}
                        onItemSelected={client => {
                          form.setFieldValue('clientId', client?.id)
                          form.setFieldValue('client', client)
                        }}
                        selectedItem={form.values.client}
                      />
                    </FieldColumn>
                  </FieldRow>

                  {(!giftCardInitialClientId && form?.values?.client?.email) ||
                  (giftCardInitialClientId &&
                    form?.values?.client?.email &&
                    giftCardInitialClientId !== form?.values?.client?.id) ? (
                    <FieldRow>
                      <FieldColumn className="max-w-[150px] font-semibold">
                        <div></div>
                      </FieldColumn>
                      <FieldColumn
                        style={{ flexDirection: isMobile ? 'column' : 'row' }}
                      >
                        <div className="w-[100%] flex items-center mb-3 mt-1">
                          <FormField.Checkbox
                            name="sendEmailToClient"
                            label="Pošalji email klijentu"
                            type="checkbox"
                            helpText="Klijent će u e-mailu dobiti informacije o pridruženom poklon bonu"
                          />
                        </div>
                      </FieldColumn>
                    </FieldRow>
                  ) : isEdit && form?.values?.client?.email ? (
                    <>
                      <FieldRow>
                        <FieldColumn className="max-w-[150px] font-semibold">
                          <div></div>
                        </FieldColumn>
                        <FieldColumn
                          style={{ flexDirection: isMobile ? 'column' : 'row' }}
                        >
                          <div className="w-[100%] flex items-center mb-1">
                            <Tooltip
                              position={'top'}
                              label="Klijent će u e-mailu dobiti informacije o pridruženom poklon bonu"
                            >
                              <ButtonTW
                                label="Pošalji email klijentu"
                                variant="secondary"
                                type="button"
                                onClick={async e => {
                                  await handleResendClientEmail()
                                }}
                              />
                            </Tooltip>
                          </div>
                        </FieldColumn>
                      </FieldRow>
                    </>
                  ) : null}

                  <FieldRow style={{ alignItems: 'flex-start' }}>
                    <FieldColumn className="lg:max-w-[150px] font-semibold">
                      <span>Opis</span>
                    </FieldColumn>
                    <FieldColumn style={{ flexDirection: 'column' }}>
                      <FormField.RichTextEditor
                        val={
                          !form.values.description
                            ? '<p></p>'
                            : Array.isArray(form.values.description)
                            ? serialize(form.values.description)
                            : form.values.description
                        }
                        name="description"
                        disabled={
                          isEdit
                            ? initialValues?.status?.id === 'active' ||
                              initialValues?.status?.id === 'expired'
                            : false
                        }
                      />
                    </FieldColumn>
                  </FieldRow>
                  <FieldRow style={{ alignItems: 'flex-start' }}>
                    <FieldColumn className="lg:max-w-[150px] font-semibold">
                      <span>Uvjeti korištenja</span>
                    </FieldColumn>
                    <FieldColumn style={{ flexDirection: 'column' }}>
                      <FormField.RichTextEditor
                        val={
                          !form.values.terms
                            ? '<p></p>'
                            : Array.isArray(form.values.terms)
                            ? serialize(form.values.terms)
                            : form.values.terms
                        }
                        name="terms"
                        disabled={
                          isEdit
                            ? initialValues?.status?.id === 'active' ||
                              initialValues?.status?.id === 'expired'
                            : false
                        }
                      />
                    </FieldColumn>
                  </FieldRow>

                  {isEdit ? (
                    <GiftCardServiceList
                      items={form.values?.items}
                      type={initialValues?.voucherType?.toLowerCase()}
                      currency={currency}
                    />
                  ) : null}
                  {/* <FieldRow style={{ alignItems: 'flex-start' }}>
                    <FieldColumn>
                      <FormField.Checkbox
                        label={'Omogući darivanje'}
                        name="isTransferable"
                        type={'checkbox'}
                      />
                    </FieldColumn>
                    <FieldColumn>
                      <FormField.Checkbox
                        label={'Jednokratno'}
                        name="singleUse"
                        type={'checkbox'}
                      />
                    </FieldColumn>
                  </FieldRow> */}

                  {isMobile &&
                  isEdit &&
                  initialValues?.status?.id === 'inactive' ? (
                    <div className="flex justify-end mt-4">
                      <div>
                        <DeleteButton
                          withConfirm
                          onConfirm={handleDeleteGiftCard}
                          confirmationMessage={t(
                            'translation.NewClientContainer.title-confirmResourceDelete'
                          )}
                        />
                      </div>
                    </div>
                  ) : null}
                </FormContainer>
              </FormFieldsWrapper>
            ) : (
              <>
                <ClientFormWrapper
                  values={form.values}
                  errors={form.errors}
                  setFieldValue={form.setFieldValue}
                  loadingAddClient={loadingAddClient}
                  isNew={true}
                  setIsNewClient={() => ({})}
                  setActiveForm={setActiveForm}
                  onGoBack={() => setActiveForm('giftcard')}
                  title={'Novi klijent'}
                  clientDetails={false}
                  isAppointment={false}
                />
              </>
            )
          }}
        </Form>
      </FormPageContainer>

      {!isMobile && !loading ? (
        <div className="flex p-4 w-full border border-t-1 shadow-[rgba(0,0,15,0.5)_0px_-6px_6px_-8px] pr-[20px] ">
          <div className="flex justify-between w-full">
            <div className="flex gap-8">
              <Button
                footerButton
                buttonType={'text'}
                name="closeForm"
                type={'button'}
                label={t('translation.ResourceEditorForm.button-close')}
                onClick={() => {
                  activeForm === 'giftcard'
                    ? initialValues.status.id === 'active' && isEdit
                      ? setIsPreviewMode(true)
                      : navigateTo.listGiftCard(params)
                    : setActiveForm('giftcard')
                }}
              />
              <Button
                onClick={() => formRef?.current?.submitForm()}
                className="w-[150px] flex !justify-center"
                buttonType={'primary'}
                name="saveChanges"
                label={t('translation.ResourceEditorForm.button-save')}
              />
            </div>
            {isEdit && initialValues?.status?.id === 'inactive' ? (
              <div className="flex justify-end mt-4">
                <div>
                  <DeleteButton
                    withConfirm
                    onConfirm={handleDeleteGiftCard}
                    confirmationMessage={t(
                      'translation.NewClientContainer.title-confirmResourceDelete'
                    )}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      {isMobile ? (
        <FormFooterButtonsContainer>
          <Button
            footerButton
            buttonType={'text'}
            name="closeForm"
            type={'button'}
            label={t('translation.ResourceEditorForm.button-close')}
            onClick={() => {
              activeForm === 'giftcard'
                ? initialValues.status.id === 'active' && isEdit
                  ? setIsPreviewMode(true)
                  : navigateTo.listGiftCard(params)
                : setActiveForm('giftcard')
            }}
          />
          <Button
            type={'submit'}
            buttonType={'primary'}
            name="saveChanges"
            onClick={() => {
              formRef?.current?.submitForm()
            }}
            label={t('translation.ResourceEditorForm.button-save')}
          />
        </FormFooterButtonsContainer>
      ) : null}
    </>
  )
}

type GiftCardServiceListProps = {
  items: RouterOutput['giftCard_findOne']['items']
  type: string
  currency: string
}
export const GiftCardServiceList = (props: GiftCardServiceListProps) => {
  const { items, type, currency } = props

  return (
    <div className="mt-[30px]">
      <p className="text-lg font-medium mb-[10px]">Odobrene usluge</p>
      {type === 'giftcard' ? (
        <div className="bg-zoyya-grayLighter rounded-sm border border-zoyya-border pr-3 pl-[1.143rem]">
          <div className="rounded-md w-full">
            <div className="w-full flex  py-2">
              {type === 'giftcard' ? (
                <>
                  <p className="w-[300px] text-sm text-zoyya-secondaryDark">
                    Vrsta usluge
                  </p>
                  <p className="mr-auto text-sm text-zoyya-secondaryDark">
                    Usluga
                  </p>
                  <div className="w-20"></div>
                </>
              ) : (
                <>
                  <p className="grow text-sm text-zoyya-secondaryDark">
                    Usluga
                  </p>
                  <p className="w-[30px] mr-[10px] text-sm text-zoyya-secondaryDark">
                    Kol
                  </p>
                  <p className="w-[70px] mr-[10px] text-sm text-zoyya-secondaryDark">
                    Cijena
                  </p>
                  <p className="w-[70px] mr-[10px] text-sm text-zoyya-secondaryDark">
                    Vrijednost
                  </p>
                  <p className="w-[40px] mr-[10px] text-center text-sm text-zoyya-secondaryDark">
                    %
                  </p>
                  <p className="w-[70px] mr-[10px] text-sm text-zoyya-secondaryDark">
                    Neto
                  </p>
                  <p className="w-[70px] mr-[10px] text-sm text-zoyya-secondaryDark">
                    Iznos
                  </p>
                  <div className="w-10"></div>
                </>
              )}
            </div>

            {items?.length ? (
              items?.map(x => (
                <div
                  key={x?.id.toString()}
                  className="w-full flex py-2 border-t border-zoyya-border "
                >
                  {type === 'giftcard' ? (
                    <>
                      <p className="w-[300px]">
                        {x?.serviceTypeId ? x?.name : ''}
                      </p>
                      <p className="mr-auto">
                        {x?.serviceId ? x?.name : 'Sve usluge'}
                      </p>
                      <div className="w-[20px]"></div>
                    </>
                  ) : x?.Service ? (
                    <>
                      <p className="grow text-[13px] mr-[10px]">{x?.name}</p>
                      <p className="min-w-[30px] mr-[10px] text-[13px]">
                        {x?.quantity || ''}
                      </p>
                      <p className="min-w-[70px] mr-[10px] text-[13px]">
                        {`${
                          formatPriceNumberWithDelimiter(x?.Service?.price) ||
                          ''
                        }`}
                      </p>
                      <p className="min-w-[70px] mr-[10px] text-[13px]">{`${formatPriceNumberWithDelimiter(
                        x?.quantity * Number(x?.Service?.price)
                      )}`}</p>
                      <p className="min-w-[40px] mr-[10px] text-center text-[13px]">
                        {formatPriceNumberWithDelimiter(
                          Number(x.discountRate) * 100,
                          false
                        ) || ''}
                      </p>
                      <p className="min-w-[70px] mr-[10px] text-[13px]">
                        {formatPriceNumberWithDelimiter(
                          Number(x?.Service?.price) -
                            roundNumberWithTwoDecimals(
                              Number(x?.Service?.price) *
                                Number(x?.discountRate)
                            )
                        )}
                      </p>
                      <p className="min-w-[80px] mr-[10px] text-[13px]">
                        {`${formatPriceNumberWithDelimiter(
                          (Number(x?.Service?.price) -
                            roundNumberWithTwoDecimals(
                              Number(x?.Service?.price) *
                                Number(x?.discountRate)
                            )) *
                            x?.quantity
                        )} ${currency}`}
                      </p>

                      <div className="w-[20px] min-w-[20px]"></div>
                    </>
                  ) : null}
                </div>
              ))
            ) : type === 'giftcard' ? (
              <div className="w-full flex py-2 border-t border-zoyya-border ">
                <p className="w-[300px]"></p>
                <p className="mr-auto">{'Sve usluge'}</p>
                <div className="w-[20px]"></div>
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <ServicesTab data={items} currency={currency} type={type} />
      )}
    </div>
  )
}
