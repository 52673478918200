import { useParams } from 'react-router-dom'
import { useHeader } from 'src/mobile/Header'
import { useSdk } from 'src/sdk'
import React, { useEffect } from 'react'
import { Loader } from 'components'
import { useFooter } from 'mobile/Footer'
import moment from 'moment'
import { trpc } from 'src/trpc'
import { useGetOrganizationByIdQuery } from 'src/state/graphql'
import { EmptyList } from 'src/components/EmptyList/EmptyList'
import { DataTable } from 'src/sdk/tw/DataTable'
import { formatPriceNumberWithDelimiter } from 'src/helpers'
import { ClientProductsUpgradePage } from 'src/components/UpgradePage/UpgradePage'

export const ClientProductsList = ({
  clientId,
  closeModal,
  isAppointmentModal,
}: {
  clientId?: string
  smallerImage?: boolean
  closeModal?: () => void
  isAppointmentModal?: boolean
}) => {
  const { t, navigateTo } = useSdk()
  const params = useParams<any>()
  const trpcContext = trpc.useContext()

  const { data: orgData, loading: orgLoading } = useGetOrganizationByIdQuery({
    variables: { id: params?.orgId },
  })
  const org = orgData?.organization?.get

  const { data: products, isLoading: productsLoading } =
    trpc.client_getSoldProducts.useQuery({
      clientId: clientId ? BigInt(clientId) : BigInt(params.id!),
      locationId: BigInt(params.locationId!),
      orgId: params.orgId!,
    })

  useHeader({
    content: t('translation.ClientProductsList.label-products'),
    onGoBack: () => navigateTo.clientDetails(params),
  })

  useFooter({
    showAddButton: false,
  })

  const columns = [
    {
      Header: t('translation.ClientProductsList.label-name'),
      accessor: 'name',
      minWidth: 200,
      width: 200,
      Cell: props => (
        <div className="flex flex-row items-center text-zoyya">
          {props.cell.row.original?.itemDescription}
        </div>
      ),
    },
    {
      Header: t('translation.ClientProductsList.label-date'),
      accessor: 'date',
      minWidth: 50,
      width: 50,
      Cell: props => (
        <div>{moment(props.cell.row.original?.date).format('DD.MM.YYYY.')}</div>
      ),
    },
    {
      Header: t('translation.ClientProductsList.label-quantity'),
      accessor: 'quantity',
      minWidth: 50,
      width: 50,
      Cell: props => (
        <div className="text-center">{props.cell.row.original?.qty}</div>
      ),
    },
    {
      Header: t('translation.ClientProductsList.label-price'),
      accessor: 'price',
      minWidth: 50,
      width: 50,
      Cell: props => (
        <div className="text-right px-2">
          {formatPriceNumberWithDelimiter(props.cell.row.original?.price) +
            ' ' +
            org?.currency?.currencyCode}
        </div>
      ),
    },

    {
      Header: t('translation.ClientProductsList.label-discount'),
      accessor: 'discountRate',
      minWidth: 50,
      width: 50,
      Cell: props => (
        <div className="px-2 text-right">
          {props.cell.row.original?.discountRate !== '0'
            ? parseFloat(
                (100 * props.cell.row.original?.discountRate).toFixed(2)
              ) + '%'
            : null}
        </div>
      ),
    },
    {
      Header: t('translation.ClientProductsList.label-total'),
      accessor: 'totalAmount',
      minWidth: 50,
      width: 50,
      Cell: props => (
        <div className="text-right px-2">
          {formatPriceNumberWithDelimiter(
            props.cell.row.original?.totalAmount
          ) +
            ' ' +
            org?.currency?.currencyCode}
        </div>
      ),
    },
  ]

  useEffect(() => {
    trpcContext.client_getSoldProducts.invalidate()
  }, [])

  const isLoading = orgLoading || productsLoading

  if (!orgLoading && org && org?.subscriptionPlan !== 'advanced') {
    return <ClientProductsUpgradePage closeModal={closeModal} />
  }

  return (
    <>
      {isLoading ? (
        <Loader isComponent />
      ) : (
        <>
          {clientId ? (
            <div className="lg:hidden text-lg py-4 text-center border-b-1 bg-zoyya-light border-zoyya-border fixed w-full top-0 pl-2 left-0 z-50">
              {t('translation.ClientProductsList.label-products')}
            </div>
          ) : null}
          {products?.length ? (
            <>
              {isAppointmentModal ? (
                <div className="flex flex-col gap-4 p-4 mt-14 lg:mt-0">
                  {products?.map(item => {
                    return (
                      <ClientProductItemMobile
                        t={t}
                        item={item}
                        currency={org?.currency?.currencyCode}
                      />
                    )
                  })}
                </div>
              ) : (
                <>
                  <div className="lg:flex hidden  flex-1 lg:min-h-[calc(100vh-120px)] lg:p-[20px]">
                    <DataTable
                      isLoading={productsLoading}
                      data={products || []}
                      columns={columns}
                      emptyListTitle={t(
                        'translation.ClientProductsList.no-sold-products'
                      )}
                    />
                  </div>
                  <div className="lg:hidden flex flex-col gap-4 p-4">
                    {products?.map(item => {
                      return (
                        <ClientProductItemMobile
                          t={t}
                          item={item}
                          currency={org?.currency?.currencyCode}
                        />
                      )
                    })}
                  </div>
                </>
              )}
            </>
          ) : (
            <div className={`m-5 ${clientId ? 'pt-14 lg:pt-0 pb-5' : ''}`}>
              <EmptyList
                title={t('translation.ClientProductsList.no-sold-products')}
              />
            </div>
          )}
        </>
      )}
    </>
  )
}

const ClientProductItemMobile = ({ item, currency, t }) => {
  return (
    <div className="rounded-lg p-4 border border-zoyya-outline">
      <p className="text-md font-semibold mb-1">{item?.itemDescription}</p>
      <p className="text-sm text-gray-500">
        {t('translation.ClientProductsList.label-date')}:{' '}
        {moment(item?.date).format('DD.MM.YYYY.')}
      </p>
      <div className="flex justify-between items-center mt-2 text-sm mb-2">
        <span className="font-medium">
          {t('translation.ClientProductsList.label-quantity')}:
        </span>
        <span className="text-center">{item?.qty}</span>
      </div>
      <div className="flex justify-between items-center  text-sm mb-2">
        <span className="font-medium">
          {t('translation.ClientProductsList.label-price')}:
        </span>
        <span className="text-center">
          {formatPriceNumberWithDelimiter(item?.price) + ' ' + currency}
        </span>
      </div>

      <div className="flex justify-between items-center  text-sm mb-2">
        <span className="font-medium">
          {t('translation.ClientProductsList.label-discount')}:
        </span>
        {item?.discountRate !== '0' ? (
          <span className="text-right">
            {parseFloat((100 * item?.discountRate).toFixed(2)) + '%'}
          </span>
        ) : (
          '-'
        )}
      </div>

      <div className="flex justify-between items-center text-sm">
        <span className="font-semibold">
          {t('translation.ClientProductsList.label-total')}:
        </span>
        <span className="text-right font-semibold">
          {formatPriceNumberWithDelimiter(item?.totalAmount) + ' ' + currency}
        </span>
      </div>
    </div>
  )
}
