import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { easeOutQuart } from 'components/style/animations'
import { device } from '../../constants'
import {
  StyledTabContainer,
  Tab,
  TabContentContainer,
  TabView,
} from 'components/TabView/TabView'
import { Button, Form, FormFooterContainer, Loader, ToolTip } from 'components'
import { DateUtil, useSdk } from 'sdk'
import { AppointmentAttachments } from 'views/AppointmentModal/components/AppointmentAttachments'
import {
  Header,
  HeaderContainer,
  HeaderWrapper,
} from 'views/Appointment/components/Header'
import moment from 'moment'
import { MobileContext } from 'src/Context'
import { ClientDetails } from 'views/AppointmentModal/components/ClientDetails'
import { ClientSelect } from 'src/sdk/tw/components/ClientSelect'
import { Service } from 'views/Appointment/components/Service'
import { FormField } from 'components'
import { chain, memoize, orderBy, some, sortBy } from 'lodash'
import { useQuery } from '@apollo/client'
import {
  queryServiceTypes,
  searchClients,
  useGetLocationLoading,
  useGetMe,
  useGetMyOrganization,
  useMutateService,
  useInviteUser,
} from 'state/queries'
import {
  analyticsTrack,
  formatPhone,
  getHoursInDay,
  getISODate,
  getLocationId,
  getOptionStatus,
  getOrgId,
  getServiceDurationInMinutes,
  getServiceHoursAndMinutes,
  getServiceWithLatestEnding,
  getStatusColor,
  getStatusLabel,
  roundNumberWithTwoDecimals,
} from 'src/helpers'
import {
  AppointmentCancellationEnum,
  useAppointmentBookMutation,
  useAppointmentCancelMutation,
  useAppointmentFindQuery,
  usePrepaymentsSendPaymentNotificationV2Mutation,
  useAppointmentsRescheduleMutation,
  useClientsAddMutation,
  useClientUpdateMutation,
  useCalendarConfirmBookingMutation,
  useResourceBookingLocationScheduleLazyQuery,
  useCalendarCancelBookingMutation,
  usePrepaymentsResendPaymentNotificationMutation,
  useCalendarCompleteAppointmentMutation,
  usePrepaymentsGetStripeConnectAccountInfoQuery,
  useCalendarConfirmAppointmentMutation,
  usePosActiveJournalGetQuery,
  ClientGetBookingsDocument,
  CalendarGetEntriesDocument,
  useGetSmsBalanceQuery,
  ClientGetAppointmentsFromResourceBookingsDocument,
  useLocationGetQuery,
  ClientSearchDocument,
} from '../../state/graphql'
import { useParams } from 'react-router'
import { resourceGetGql } from 'state/gql'
import { ClientFormWrapper } from 'views/AppointmentModal/components/ClientFormWrapper'
import { UpdateStatusModal } from 'views/Appointment/UpdateStatus'
import {
  FormikComputedProps,
  FormikHandlers,
  FormikHelpers,
  FormikProps,
  FormikRegistration,
  FormikSharedConfig,
  FormikState,
} from 'formik'
import { ButtonStyled } from 'components/Button/Button'
import * as Yup from 'yup'
import { StyledFieldsContainer } from 'components/Form/Form'
import { FormsContainer } from 'src/styles'
import { isValidNumber } from 'libphonenumber-js'
import { PaymentNotification } from 'views/AppointmentModal/components/PaymentNotification'
import { getOrganizationByIdGql } from 'state/query/getOrganizationById'
import { FieldRow, FieldColumn } from 'views/Settings/styles'
import { ClientDetailsGroup } from 'views/AppointmentModal/components/ClientDetailsGroup'
import { NewReceipt } from 'views/CashRegister/NewReceipt/NewReceipt'
import { PosReceiptStore, usePosStore } from 'state/local/posReceiptStore'
import {
  appointmentBookMutationUpdateCache,
  appointmentCancelUpdateCache,
  appointmentRescheduleUpdateCache,
} from 'state/local/apolloCacheUpdater'
import { AppointmentChangelog } from './AppointmentChangelog'
import { ServiceForm as ServiceForm2 } from 'views/Settings/Services/ServiceEditor/ServiceForm'
import { ThemeContext } from 'styled-components'
import { useACL } from 'src/sdk/acl'
import { CopyAppointment } from './CopyAppointment'
import { AppointmentBookMutation } from '../../state/graphql'
import { useCalendarStore } from 'src/state/local/calendarStore'
import { nanoid } from 'nanoid'
import { trpc } from 'src/trpc'
import { useValidationSchema } from 'src/sdk/useValidationSchema'
import { GroupAppointmentEditScreen } from '../GroupAppointment/GroupAppointmentEditScreen'
import Warning from 'components/Icon/Icons/Warning'
import { AppointmentClientReminders } from './AppointmentClientReminders'
import { AppointmentClientRemindersForm } from './AppointmentClientRemindersForm'
import { WarningTriangle } from 'src/components/Icon/Icons/WarningTriangle'
import { ClientGiftCardsList } from '../Clients/ClientGiftCardsList'
import { ClientProductsList } from '../Clients/ClientProductsList'

const ServiceSchema = t =>
  Yup.object().shape({
    services: Yup.array()
      .of(
        Yup.object().shape({
          startTime: Yup.string().required(
            t('translation.AppointmentModal.validation-nameRequired')
          ),
          employee: Yup.string().required(
            t('translation.AppointmentModal.validation-service-type-required')
          ),
          duration: Yup.string().required(
            t('translation.AppointmentModal.validation-duration-required')
          ),
        })
      )
      .required(
        t('translation.AppointmentModal.validation-service-type-required')
      ),
  })

const AddNewServiceSchema = t =>
  Yup.object().shape({
    name: Yup.string().required(
      t('translation.AppointmentModal.validation-nameRequired')
    ),
    type: Yup.object()
      .nullable()
      .required(
        t('translation.AppointmentModal.validation-service-type-required')
      ),
    durationMinutes: Yup.string().required(
      t('translation.AppointmentModal.validation-duration-required')
    ),
    netDurationMinutes: Yup.string()
      .required(t('translation.ServiceEditorForm.validation-duration-required'))
      .test(
        'validDurationMinutes',
        t('translation.ServiceEditorForm.validation-net-duration'),
        function (value) {
          const { parent } = this
          const durationMinutes = Number.isInteger(
            Number(parent?.durationMinutes)
          )
            ? Number(parent?.durationMinutes)
            : getServiceDurationInMinutes(parent?.durationMinutes)

          return (
            Number.isInteger(Number(value)) && Number(value) <= durationMinutes
          )
        }
      ),
    price: Yup.string().matches(
      /^-?(?:\d+)(?:(\.|,)\d+)?$/,
      t('translation.ServiceEditorForm.validation-numeric-data-only')
    ),
    vatRate: Yup.string().matches(
      /^-?(?:\d+)(?:(\.|,)\d+)?$/,
      t('translation.ServiceEditorForm.validation-numeric-data-only')
    ),
  })

const CancellationSchema = t =>
  Yup.object().shape({
    cancelReason: Yup.string().required(
      t('translation.AppointmentModal.cancelReason')
    ),
  })

type TAppointmentModalProps = {
  newEvent?: any
  eventId?: string | null
  isNew?: boolean
  handleOnCloseEvent?: any
  isDashboard?: boolean
  selectedEmployeesInWeek?: any
}
export const Appointment = (props: TAppointmentModalProps) => {
  const { eventId, isNew, handleOnCloseEvent, isDashboard } = props
  let shouldSkipGetEvent = isNew || !eventId
  const { data: booking, isLoading: loadingAppointment } =
    trpc.booking_find.useQuery({
      id: BigInt(eventId || 0),
      locationId: BigInt(getLocationId()!),
      orgId: getOrgId()!,
    })

  if (shouldSkipGetEvent || booking?.kind === 'PRIVATE') {
    return <PrivateBookingEvent {...props} />
  }
  if (loadingAppointment) return <Loader />

  if (booking?.kind === 'GROUP') {
    return (
      <GroupAppointmentEditScreen
        onClose={props.handleOnCloseEvent}
        id={booking.id}
      />
    )
  }

  if (booking?.kind === 'GROUP_SLOT' && booking.groupBookingId) {
    return <PrivateBookingEvent {...props} isGroupAppointmentSlot={true} />
  }
  return null
}

const PrivateBookingEvent = (
  props: TAppointmentModalProps & { isGroupAppointmentSlot?: boolean }
) => {
  const {
    newEvent,
    eventId,
    isNew,
    handleOnCloseEvent,
    isDashboard,
    isGroupAppointmentSlot,
  } = props

  const { t, appServices, navigateTo } = useSdk()
  const calendarRef = useRef()
  const { apolloClient } = appServices
  const date = moment().format('YYYY-MM-DD')
  const isMobile = useContext(MobileContext)
  const selectedTheme = useContext(ThemeContext)
  const formRef = useRef<FormikProps<any>>()
  const { ClientSchema } = useValidationSchema()

  const { locationId, orgId, view } = useParams<any>()

  const [isBusy, setIsBusy] = useState(false)
  const [preparingForIssueReceipt, setPreparingForIssueReceipt] =
    useState(false)
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [activeForm, setActiveForm] = useState('appointment')
  const [appointmentStatus, setAppointmentStatus] = useState<
    | ''
    | 'confirmBooking'
    | 'cancelBooking'
    | 'cancel'
    | 'backToConfirm'
    | 'conclude'
  >('')
  const [isNewClient, setIsNewClient] = useState(false)
  const [filteredResources, setFilteredResources] = useState<any>()
  const [serviceInEdit, setServiceInEdit] = useState<any>()
  const [clientsSortBy, setClientsSortBy] = useState('')
  const [error, setError] = useState<string | undefined>('')
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const [serviceMinDuration, setServiceMinDuration] = useState<boolean>(false)
  // const [isClientDisabled, setIsClientDisabled] = useState<boolean>(false)

  const [isNewReminder, setIsNewReminder] = useState(false)

  const { hasPermission } = useACL()
  const canAccessInvoicing = hasPermission('ACCESS_INVOICING')
  const canEditAppointment = hasPermission('EDIT_APPOINTMENT')

  useEffect(() => {
    const clientsSortBy = localStorage.getItem('clientsSortBy')
    if (clientsSortBy) {
      setClientsSortBy(clientsSortBy)
    } else setClientsSortBy('id_desc')
  }, [])

  const store = useCalendarStore()
  // const store = useCalendarStore()
  // const { openEditEventModal, openNewEventModal } = store
  // useEffect(() => {
  // if(eventId && isNew)
  // openEditEventModal(!!eventId ? eventId : '')
  // }, [eventId, isNew])

  const [appointmentBookMutation, { loading: loadingAddAppointment }] =
    useAppointmentBookMutation()

  const [appointmentRescheduleMutation, { loading: loadingEditEvent }] =
    useAppointmentsRescheduleMutation()

  const { data: smsBalanceData, loading: smsBalanceLoading } =
    useGetSmsBalanceQuery({ fetchPolicy: 'network-only' })

  const smsBalance = smsBalanceData?.payment?.getSmsBalance?.balance || 0

  const selectedPosDevice = usePosStore(store => store.pos.selectedPosDevice)

  // GET Reminder Types

  const { data: remindersConfigData } = trpc.remindersConfig_get.useQuery({
    orgId,
    locationId: BigInt(locationId),
  })

  // GET Active journal
  const { data: journalData, loading: journalLoading } =
    usePosActiveJournalGetQuery({
      variables: {
        input: {
          fisLocationId: selectedPosDevice?.fisLocationId as any,
          fisDeviceId: selectedPosDevice?.fisDeviceId as any,
        },
      },
      skip: !selectedPosDevice?.fisLocationId,
    })
  const journal = journalData?.posActiveJournal_get
  const userData = useGetMe()

  let shouldSkipGetEvent = isNew || !eventId
  const {
    data: appointmentData,
    loading: loadingAppointment,
    refetch: refetchAppointment,
  } = useAppointmentFindQuery({
    variables: { appointmentId: parseInt(eventId || '') },
    skip: shouldSkipGetEvent,
    fetchPolicy: 'no-cache',
  })
  const appointment = appointmentData?.appointment?.find

  //region GET LOCATION
  const { data: viewLocationData, loading: loadingLocation } =
    useLocationGetQuery({
      variables: {
        locationId,
      },
    })

  const viewLocation = viewLocationData?.location?.get
  //endregion

  //region GET EMPLOYEES & SCHEDULE
  const [resourceBookingLocationScheduleQuery, { data: locationScheduleData }] =
    useResourceBookingLocationScheduleLazyQuery({
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
    }) // (getLocationId(), dateFrom, dateTo)

  const resourceId = newEvent?.resourceId
  const { data: resourceData, loading: loadingResource } = useQuery(
    resourceGetGql,
    {
      variables: { id: newEvent?.resourceId },
      skip: !!resourceId === false,
    }
  )
  const resourcesData = viewLocation?.resources || []

  const employees = useMemo(
    () => resourcesData?.filter(x => x.showInCalendar && x.kind === 'USER'),
    [resourcesData, loadingLocation]
  )

  const resources = useMemo(
    () => resourcesData?.filter(x => x.kind === 'RESOURCE'),
    [resourcesData, loadingLocation]
  )

  const locationSchedule =
    locationScheduleData?.resourceBooking.locationSchedule
  //endregion

  //region GET TIME SLOTS & WORKING HOURS
  const getTimeSlotState = memoize(
    (date, selectedHour, selectedEmployee) => {
      let isBusy = false
      let isInactive = false
      const dateMoment = moment(date)
      const dateStr = dateMoment.format('YYYY-MM-DD')
      const employeeSchedule = locationSchedule?.filter(
        x => x?.resource?.id === selectedEmployee
      )

      // @ts-ignore
      const employeeSlots = employeeSchedule?.reduce((slots, schedule) => {
        // @ts-ignore
        return [...slots, ...schedule?.timeSlots]
      }, [])

      // @ts-ignore
      for (let slot of employeeSlots) {
        if (
          moment(dateStr + ' ' + selectedHour).isBetween(
            dateStr + ' ' + slot.startTime,
            dateStr + ' ' + slot.endTime,
            null,
            '[)'
          )
        ) {
          if (slot.kind === 'OFF') {
            isInactive = true
            isBusy = false
          } else if (slot.kind === 'BUSY') {
            isBusy = true
            isInactive = false
          } else {
            isInactive = false
            isBusy = false
          }
          break
        }
      }

      return { isBusy, isInactive }
    },
    (date, selectedHour, selectedEmployee) =>
      `${date}-${selectedHour}-${selectedEmployee}`
  )

  const getWorkHours = memoize(
    (date, resourceId, timeSlotDuration) => {
      const timesInDay = getHoursInDay(timeSlotDuration)
      if (!locationSchedule?.[0]) {
        return timesInDay
      }
      return timesInDay.map(time => {
        const { isInactive, isBusy } = getTimeSlotState(
          date,
          time.id?.toString(),
          resourceId
        )
        // if we are watching slots for the current appointment, we dont set up isBusy flag
        const dateMoment = moment(date)
        const dateStr = dateMoment.format('YYYY-MM-DD')

        const isCurrentAppointmentSlot = moment(
          dateStr + ' ' + time?.id.toString()
        ).isBetween(
          dateStr + ' ' + appointment?.startTime,
          dateStr + ' ' + appointment?.endTime,
          null,
          '[)'
        )

        return {
          ...time,
          isInactive,
          isBusy: isCurrentAppointmentSlot ? false : isBusy,
        }
      })
    },
    (date, resourceId, timeSlotDuration) => {
      return `${date}-${resourceId}-${timeSlotDuration}`
    }
  )
  //endregion

  //region GET ORGANIZATION
  const orgData = useGetMyOrganization()
  const org = orgData?.data?.organization?.my?.find(x => x.id === orgId)
  const { data: organizationData, loading: loadingOrgData } = useQuery(
    getOrganizationByIdGql,
    {
      variables: { id: orgId },
    }
  )

  const getOrganizationData = organizationData?.organization?.get

  const hasOnlineBooking = getOrganizationData?.orgFeatures?.hasOnlineBooking

  const {
    data: stripeConnectAccountData,
    loading: loadingStripeConnectAccountData,
  } = usePrepaymentsGetStripeConnectAccountInfoQuery({
    skip: !getOrganizationData?.stripeConnectAccountId,
  })
  const hasStripeChargesEnabled =
    stripeConnectAccountData?.prepayments?.getStripeConnectAccountInfo
      ?.hasChargesEnabled

  const hasInvoicingData =
    viewLocation?.prepaymentsConfig?.userId &&
    viewLocation?.prepaymentsConfig?.operatorId &&
    viewLocation?.prepaymentsConfig?.prepaymentsDeviceId &&
    viewLocation?.prepaymentsConfig?.prepaymentServiceId

  const hasPrepaymentsCredentials =
    getOrganizationData?.prepaymentsProvider === 'STRIPE'
      ? hasStripeChargesEnabled && hasInvoicingData
      : getOrganizationData?.hasVivaMerchantId &&
        getOrganizationData?.hasVivaApiKey &&
        hasInvoicingData

  //endregion
  const hasPrepaymentsEnabled =
    viewLocation?.prepaymentsConfig?.prepaymentsEnabled

  //region GET SERVICES
  //const serviceTypesData = useGetServiceTypes(locationId) // problems with refreshing data after adding service
  const serviceTypesData = useQuery(queryServiceTypes, {
    variables: { locationId },
    fetchPolicy: 'network-only',
  })

  const serviceTypes = serviceTypesData.data?.serviceType?.search

  const services = useMemo(() => {
    return serviceTypes?.map(type => {
      const sortedServices = type.services
        ?.slice()
        ?.sort(
          (a, b) =>
            a.sequence?.localeCompare(b.sequence, undefined, { numeric: true })
        )
      const options = sortedServices.map(service => {
        return {
          ...service,
          id: service.id,
          label: service.name,
          currency: org?.currency?.id || '',
          resourceTypeId: service.resourceType?.id,
        }
      })
      return {
        ...type,
        label: type.name,
        options: orderBy(options, 'hasProfession'),
      }
    })
  }, [serviceTypes, org])

  const flattenedServices = useMemo(
    () => chain(serviceTypes).map('services').flatten().value(),
    [serviceTypes]
  )

  useEffect(() => {
    if (!serviceInEdit?.service) return
    const selectedService = flattenedServices?.find(
      x => x.id === serviceInEdit?.service
    )
    const filteredResources = resources?.filter(x =>
      some(x.professions, { id: selectedService?.resourceType?.id })
    )

    setFilteredResources(filteredResources)
  }, [flattenedServices, resources, serviceInEdit])
  //endregion

  //region HELPERS
  const getServicesDuration = services => {
    return DateUtil.minutesToDurationString(services.durationMinutes)
  }

  const durationOptions = getHoursInDay(viewLocation?.timeSlotDuration)

  const workingEmployees = memoize(
    (date, startTime) => {
      if (!locationSchedule?.[0]) {
        return []
      }

      return employees?.map(employee => {
        const { isInactive, isBusy } = getTimeSlotState(
          date,
          startTime?.toString(),
          employee.id
        )
        return {
          ...employee,
          isInactive: isInactive,
          isBusy,
        }
      })
    },
    (date, startTime) => `${date}-${startTime}`
  )

  const workingResources = memoize(
    (date, startTime) => {
      if (!locationSchedule?.[0]) {
        return
      }
      return filteredResources
        ?.map(resource => {
          const { isInactive, isBusy } = getTimeSlotState(
            date,
            startTime?.toString(),
            resource.id
          )
          return {
            ...resource,
            isInactive: isInactive,
            isBusy,
          }
        })
        ?.sort((a, b) => a?.isBusy - b?.isBusy)
        ?.sort((a, b) => a?.isInactive - b?.isInactive)
    },
    (date, startTime) => `${date}-${startTime}`
  )

  function getOnNewClient(
    form: FormikSharedConfig<{}> &
      FormikState<any> &
      FormikHelpers<any> &
      FormikHandlers &
      FormikComputedProps<any> &
      FormikRegistration & { submitForm: () => Promise<any> }
  ) {
    return () => {
      form.setFieldValue('client', null)
      setActiveForm('client')
      setIsNewClient(true)
      form.setFieldValue('firstName', '')
      form.setFieldValue('lastName', '')
      form.setFieldValue('email', '')
      form.setFieldValue('mobilePhone', '')
      form.setFieldValue('address', '')
      form.setFieldValue('zipCode', '')
      form.setFieldValue('city', '')
      form.setFieldValue('gender', '')
      form.setFieldValue('comment', '')
      form.setFieldValue('paymentRequired', true)
    }
  }

  function getOnEditClient(
    form: FormikSharedConfig<{}> &
      FormikState<any> &
      FormikHelpers<any> &
      FormikHandlers &
      FormikComputedProps<any> &
      FormikRegistration & { submitForm: () => Promise<any> },
    values
  ) {
    return () => {
      setIsNewClient(false)
      setActiveForm('client')
      form.setFieldValue('firstName', values?.client?.firstName)
      form.setFieldValue('lastName', values?.client?.lastName)
      form.setFieldValue('email', values?.client?.email)
      form.setFieldValue('mobilePhone', values?.client?.mobilePhone)
      form.setFieldValue('address', values?.client?.address)
      form.setFieldValue('zipCode', values?.client?.zipCode)
      form.setFieldValue('city', values?.client?.city)
      form.setFieldValue('gender', values?.client?.gender)
      form.setFieldValue('comment', values?.client?.comment)
      form.setFieldValue('birthDate', values?.client?.birthDate)
      form.setFieldValue('vatId', values?.client?.vatId)
      form.setFieldValue('country', values?.client?.country)
      form.setFieldValue('sendReminderEmail', values?.client?.sendReminderEmail)
      form.setFieldValue('sendReminderSms', values?.client?.sendReminderSms)
      form.setFieldValue('sendBookingEmail', values?.client?.sendBookingEmail)
      form.setFieldValue('sendNewsEmail', values?.client?.sendNewsEmail)
      form.setFieldValue('sendHelpEmail', values?.client?.sendHelpEmail)
      form.setFieldValue('paymentRequired', values?.client?.paymentRequired)
    }
  }

  function getOnNewReminder(
    form: FormikSharedConfig<{}> &
      FormikState<any> &
      FormikHelpers<any> &
      FormikHandlers &
      FormikComputedProps<any> &
      FormikRegistration & { submitForm: () => Promise<any> }
  ) {
    return () => {
      form.setFieldValue('reminder.reminderTime', '08:00')
      form.setFieldValue('reminder.reminderDate', null)
      form.setFieldValue('reminder.messageToClient', '')
      if (remindersConfigData?.length === 1) {
        form.setFieldValue(
          'reminder.reminderConfigId',
          remindersConfigData[0].id
        )
      } else {
        form.setFieldValue('reminder.reminderConfigId', null)
      }
      setActiveForm('reminder')
      // setIsNewReminder(true)
    }
  }

  //endregion

  //region INITIAL VALUES & VALUE CHANGE
  const [selectedDayId, setSelectedDayId] = useState(
    newEvent?.selectedDayId || appointment?.date
  )
  useEffect(() => {
    setSelectedDayId(newEvent?.selectedDayId || appointment?.date)
  }, [newEvent?.selectedDayId, appointment?.date])

  const assignToResource = resourceId
    ? resourceData?.resource?.get
    : employees?.find(x => x.id === userData?.data?.user?.me?.resource?.id)
    ? employees?.find(x => x.id === userData?.data?.user?.me?.resource?.id)
    : null

  useEffect(() => {
    if (!selectedDayId) return
    resourceBookingLocationScheduleQuery({
      variables: {
        dateFrom: moment(selectedDayId).format('YYYY-MM-DD'),
        dateTo: moment(selectedDayId).format('YYYY-MM-DD'), // moment(date).add(1, 'day').format('YYYY-MM-DD'),
        locationId,
      },
    })
  }, [
    assignToResource,
    date,
    locationId,
    resourceBookingLocationScheduleQuery,
    selectedDayId,
  ])

  const totalPrice = !isNew
    ? Math.round(
        (appointment?.services?.reduce((acc, curr) => {
          return acc + (curr?.totalAmount || 0)
        }, 0) || 0 + Number.EPSILON) * 100
      ) / 100
    : 0

  const generateDefaultAppointmentCopies = values => {
    const bookingServices = values?.services

    if (!bookingServices?.length) return []

    const sortedBookingServices = sortBy(
      bookingServices,
      typeof bookingServices[0].startTime === 'object'
        ? 'startTime.title'
        : 'startTime'
    )
    const firstStartingService = sortedBookingServices[0]
    const lastEndingService =
      sortedBookingServices[sortedBookingServices.length - 1]

    const startTime =
      typeof firstStartingService?.startTime === 'object'
        ? firstStartingService?.startTime?.title
        : firstStartingService?.startTime
    const endTime = lastEndingService?.endTime

    const durationMinutes = moment(moment(lastEndingService?.endTimeUtc)).diff(
      moment(moment(firstStartingService?.startTimeUtc)),
      'minutes'
    )
    const sortedCopied = sortBy(values?.appointmentCopies, copy => {
      return moment(
        `${moment(copy?.date).format('YYYY-MM-DD')} ${copy?.startTime?.title}`
      ).toISOString()
    })

    const latestCopiedAppointment = sortedCopied[sortedCopied.length - 1]
    const date = latestCopiedAppointment
      ? latestCopiedAppointment?.date
      : values?.date

    return [
      {
        date: moment(date)?.format('YYYY-MM-DD'),
        startTimeUtc: moment(
          `${moment(date)?.format('YYYY-MM-DD')} ${startTime}`
        ).toISOString(),
        durationMinutes,
        duration: getServiceHoursAndMinutes(durationMinutes),
        startTime: {
          id: startTime,
          title: startTime,
        },
        endTime: {
          id: endTime,
          title: endTime,
        },
        endTimeUtc: moment(
          `${moment(date)?.format('YYYY-MM-DD')} ${endTime}`
        ).toISOString(),
      },
    ]
  }

  const initialValues = isNew
    ? {
        id: null,
        notes: null,
        attachments: [],
        endTime: isMobile
          ? ''
          : getServiceHoursAndMinutes(
              getServiceDurationInMinutes(
                newEvent?.selectedHour
                  ? `${newEvent.selectedHour}:${newEvent.selectedMinute}`
                  : moment().format('HH') + ':30'
              ) + viewLocation?.timeSlotDuration
            ),
        startTime: newEvent?.selectedHour
          ? `${newEvent.selectedHour}:${newEvent.selectedMinute}`
          : moment().format('HH') + ':30' || '',
        date: moment(selectedDayId).format('YYYY-MM-DD'),
        sendAppointmentEmail: viewLocation?.sendAppointmentEmailsToClients,
        sendAppointmentSms: viewLocation?.sendAppointmentSmsToClients,
        appointmentCopySendAppointmentEmail:
          viewLocation?.sendAppointmentEmailsToClients,
        sendReminderSms: true,
        sendReminderEmail: true,
        client: null,
        sendPaymentRequest:
          hasPrepaymentsCredentials && totalPrice > 0 && hasPrepaymentsEnabled,
        appointmentCopySendPaymentRequest:
          hasPrepaymentsCredentials && totalPrice > 0 && hasPrepaymentsEnabled,
        cancelAppointmentOnExpiredPaymentRequest:
          hasPrepaymentsCredentials && totalPrice > 0 && hasPrepaymentsEnabled
            ? viewLocation?.prepaymentsConfig
                ?.cancelAppointmentOnExpiredPaymentRequest
            : false,
        totalPrice,
        //if on mobile, we don't need the initial services state
        services: isMobile
          ? []
          : [
              {
                isNew: true,
                isEdit: true,
                isNotSaved: true,
                duration: getServiceHoursAndMinutes(
                  viewLocation?.timeSlotDuration
                ),
                durationMinutes: viewLocation?.timeSlotDuration,
                endTime: getServiceHoursAndMinutes(
                  getServiceDurationInMinutes(
                    newEvent?.selectedHour
                      ? `${newEvent.selectedHour}:${newEvent.selectedMinute}`
                      : moment().format('HH') + ':30'
                  ) + viewLocation?.timeSlotDuration
                ),
                endTimeUtc: moment(
                  moment(getISODate())?.format('YYYY-MM-DD') +
                    ' ' +
                    getServiceHoursAndMinutes(
                      getServiceDurationInMinutes(
                        newEvent?.selectedHour
                          ? `${newEvent.selectedHour}:${newEvent.selectedMinute}`
                          : moment().format('HH') + ':30'
                      ) + viewLocation?.timeSlotDuration
                    )
                ).toISOString(),
                startTime: {
                  id: newEvent?.selectedHour
                    ? `${newEvent.selectedHour}:${newEvent.selectedMinute}`
                    : moment().format('HH') + ':30',
                  title: newEvent?.selectedHour
                    ? `${newEvent.selectedHour}:${newEvent.selectedMinute}`
                    : moment().format('HH') + ':30',
                },
                startTimeUtc: getISODate(),
                employee: assignToResource?.id,
                resourceId: null,
                service: null,
                resourceBookingId: null,
                price: 0,
                discountAmount: 0,
                discountRate: 0,
                discountType: 'percent',
                discountValue: 0,
                currencyId: org?.currency?.id,
                totalAmount: 0,
                id: 0,
              },
            ],
      }
    : {
        ...appointment,
        // if paid and not past due, default is true but it can be changed. If past due default is false but still can be changed
        attachments: appointment?.attachments,
        shouldRefundPaymentRequest:
          appointment?.status === 'DRAFT' // booking request
            ? true
            : !moment().isAfter(
                appointment?.posPaymentRequest?.paymentRequestDueDate
              ),
        sendEmail: viewLocation?.sendAppointmentEmailsToClients,
        sendReviewRequestSms: viewLocation?.notifications_smsReviewRequest,
        sendReviewRequestEmail: viewLocation?.notifications_emailReviewRequest,
        sendAppointmentEmail:
          viewLocation?.sendAppointmentEmailsToClients &&
          !!appointment?.client?.email,
        appointmentCopySendAppointmentEmail:
          viewLocation?.sendAppointmentEmailsToClients &&
          !!appointment?.client?.email,
        // default value for send sms checkbox on appointment screen (create or update start time)
        sendAppointmentSms:
          viewLocation?.sendAppointmentSmsToClients &&
          !!appointment?.client?.mobilePhone,
        // default value for send sms checkbox on update status modal
        sendSms:
          viewLocation?.sendAppointmentSmsToClients &&
          !!appointment?.client?.mobilePhone,
        appointmentCopies: generateDefaultAppointmentCopies(appointment),
        services: appointment?.services?.map(x => {
          const employeeProfessions = x?.employee?.professions
            ? [...x?.employee?.professions?.map(x => x?.id), undefined]
            : [undefined]

          const hasProfession = employeeProfessions?.includes(
            x?.service?.profession?.id?.toString()
          )

          return {
            ...x,
            service: x.service?.id,
            employee: x.employee?.id,
            resourceId: x.resource?.id,
            missingProfession: !x?.service?.profession?.id
              ? false
              : !hasProfession,
            startTime: {
              id: x.startTime,
              title: x.startTime,
            },
            isEdit: false,
            price: x?.price,
            discountRate: x?.discountRate,
            discountAmount: x?.discountAmount,
            discountValue:
              x?.discountType === 'percent'
                ? x?.discountRate
                : x?.discountAmount,
            discountType: x?.discountType || 'percent',
            currencyId: x?.currencyId,
            totalAmount: x?.totalAmount,
            giftCardAmount: x?.giftCardAmount,
            giftCard: x?.giftCard
              ? {
                  ...x?.giftCard,
                  id: BigInt(x?.giftCard?.id),
                }
              : null,
            original: {
              id: x?.id,
              duration: x?.duration,
              durationMinutes: x?.durationMinutes,
              employee: x?.employee?.id,
              endTime: x?.endTime,
              endTimeUtc: x?.endTimeUtc,
              startTime: {
                id: x.startTime,
                title: x.startTime,
              },
              startTimeUtc: x?.startTimeUtc,
              resourceId: x?.resource?.id,
              // resourceType: x?.resourceType,
              service: x?.service?.id,
              price: x?.price,
              discountRate: x?.discountRate,
              discountAmount: x?.discountAmount,
              discountValue:
                x?.discountType === 'percent'
                  ? x?.discountRate
                  : x?.discountAmount,
              discountType: x?.discountType || 'percent',
              currencyId: x?.currencyId,
              totalAmount: x?.totalAmount,
              giftCardAmount: x?.giftCardAmount,
              giftCard: x?.giftCard
                ? {
                    ...x?.giftCard,
                    id: BigInt(x?.giftCard?.id),
                  }
                : null,
            },
          }
        }),
        employeeId: appointment?.services[0]?.employee?.id || null,
        totalPrice,
        sendPaymentRequest: appointment?.client
          ? hasPrepaymentsCredentials &&
            totalPrice > 0 &&
            hasPrepaymentsEnabled &&
            appointment?.client?.paymentRequired
          : hasPrepaymentsCredentials &&
            totalPrice > 0 &&
            hasPrepaymentsEnabled,
        appointmentCopySendPaymentRequest: appointment?.client
          ? hasPrepaymentsCredentials &&
            totalPrice > 0 &&
            hasPrepaymentsEnabled &&
            appointment?.client?.paymentRequired
          : hasPrepaymentsCredentials &&
            totalPrice > 0 &&
            hasPrepaymentsEnabled,
        processingStatus: appointment?.processingStatus,
        appointment_cancelAppointmentOnExpiredPaymentRequest:
          appointment?.cancelAppointmentOnExpiredPaymentRequest,
      }

  useEffect(() => {
    if (isNew) {
      setSelectedTabIndex(0)
    }
  }, [isNew])

  const handleValueChange = (name, value, values, form) => {
    if (activeForm === 'client' && name === 'email') {
      const isEmailInvalid = !value || !!formRef?.current?.errors?.email

      if (isEmailInvalid) {
        form.setFieldValue('sendReminderEmail', false)
      } else {
        form.setFieldValue('sendReminderEmail', true)
      }
    }
    if (
      activeForm === 'client' &&
      name === 'email' &&
      value !== value?.trim()
    ) {
      form.setFieldValue('email', value?.trim())
      formRef.current?.validateForm()
    }
    if (name === 'durationMinutes') {
      form.setFieldValue(
        'netDurationMinutes',
        getServiceDurationInMinutes(value)
      )
    }
    if (name === 'sendPaymentRequest') {
      if (value) {
        form.setFieldValue('sendAppointmentEmail', true)
        form.setFieldValue('sendAppointmentSms', false)
        form.setFieldValue(
          'cancelAppointmentOnExpiredPaymentRequest',
          viewLocation?.prepaymentsConfig
            ?.cancelAppointmentOnExpiredPaymentRequest
        )
      }
    }
    if (name === 'date') {
      form.setFieldValue(
        'sendAppointmentEmail',
        viewLocation?.sendAppointmentEmailsToClients
      )
      form.setFieldValue(
        'appointmentCopies',
        generateDefaultAppointmentCopies(values)
      )
    }
    if (name === 'client') {
      const total =
        Math.round(
          (values?.services?.reduce((acc, curr) => {
            return acc + (curr?.totalAmount || 0)
          }, 0) || 0 + Number.EPSILON) * 100
        ) / 100

      if (
        hasPrepaymentsCredentials &&
        total > 0 &&
        hasPrepaymentsEnabled &&
        !values?.services?.some(ser => ser?.giftCard)
      ) {
        form.setFieldValue('sendPaymentRequest', value?.paymentRequired)
      }

      form.setFieldValue(
        'sendAppointmentEmail',
        viewLocation?.sendAppointmentEmailsToClients && !!values?.client?.email
      )
    }
    if (name === 'services') {
      const selectedService = flattenedServices?.find(x => x.isEdit === true)

      const filteredResources = resources?.filter(x =>
        some(x.professions, { id: selectedService?.resourceType?.id })
      )
      setFilteredResources(filteredResources)
      form.setFieldValue(
        'appointmentCopies',
        generateDefaultAppointmentCopies(values)
      )
    }
    if (name.startsWith('services') && name.endsWith('startTime')) {
      const id = name?.slice(name?.indexOf('[') + 1, name?.lastIndexOf(']'))

      if (
        getServiceDurationInMinutes(value?.title) +
          getServiceDurationInMinutes(values?.services[Number(id)]?.duration) >
        getServiceDurationInMinutes('24:00') - viewLocation?.timeSlotDuration!
      ) {
        const endTime = getServiceHoursAndMinutes(
          getServiceDurationInMinutes(value?.title) +
            getServiceDurationInMinutes('24:00') -
            viewLocation?.timeSlotDuration! -
            getServiceDurationInMinutes(value?.title)
        )
        const endTimeUtc = moment(
          moment(values?.services[Number(id)]?.endTimeUtc)?.format(
            'YYYY-MM-DD'
          ) +
            ' ' +
            endTime
        )
        form.setFieldValue(
          `services[${id}].duration`,
          getServiceHoursAndMinutes(
            getServiceDurationInMinutes('24:00') -
              viewLocation?.timeSlotDuration! -
              getServiceDurationInMinutes(value?.title)
          )
        )
        form.setFieldValue(
          `services[${id}].durationMinutes`,
          getServiceDurationInMinutes('24:00') -
            viewLocation?.timeSlotDuration! -
            getServiceDurationInMinutes(value?.title)
        )
        form.setFieldValue(`services[${id}].endTime`, endTime)
        form.setFieldValue(
          `services[${id}].endTimeUtc`,
          endTimeUtc?.toISOString()
        )
      }

      form.setFieldValue(
        'sendAppointmentEmail',
        viewLocation?.sendAppointmentEmailsToClients && !!values?.client?.email
      )
      form.setFieldValue(
        'appointmentCopies',
        generateDefaultAppointmentCopies(values)
      )
    }
    if (name.startsWith('services') && name.endsWith('giftCard')) {
      const id = name?.slice(name?.indexOf('[') + 1, name?.lastIndexOf(']'))
      if (value) {
        const service = values?.services[Number(id)]

        var giftCardAmount = 0
        var discountAmount = 0
        var discountRate = 0
        var totalAmount = 0
        var price = 0

        const item = value?.items?.find(
          x => parseInt(x?.serviceId) === parseInt(service.service)
        )
        if (service?.original?.giftCard?.id === value?.id) {
          giftCardAmount = service?.original?.giftCardAmount
          discountAmount = service?.original?.discountAmount
          discountRate = service?.original?.discountRate
          totalAmount = service?.original?.totalAmount
          price = service?.original?.price
        } else if (value?.giftCardType === 'VOUCHER') {
          price = item?.servicePrice
          discountRate = parseFloat((100 * item?.discountRate).toFixed(2))
          discountAmount = item?.servicePrice - item?.giftCardPrice
          giftCardAmount = item?.giftCardPrice
          totalAmount = price - discountAmount
        } else if (value?.giftCardType === 'GIFTCARD') {
          price = item?.servicePrice ? item?.servicePrice : service?.price
          giftCardAmount = value?.balance > price ? price : value?.balance
          totalAmount = price - discountAmount
        }

        form.setFieldValue(`services[${id}].discountType`, 'percent')
        form.setFieldValue(`services[${id}].discountValue`, discountRate)
        form.setFieldValue(`services[${id}].giftCardAmount`, giftCardAmount)
        form.setFieldValue(`services[${id}].discountRate`, discountRate)
        form.setFieldValue(`services[${id}].discountAmount`, discountAmount)
        form.setFieldValue(`services[${id}].totalAmount`, totalAmount)
        form.setFieldValue(`sendPaymentRequest`, false)
      } else {
        form.setFieldValue(`services[${id}].discountType`, 'percent')
        form.setFieldValue(`services[${id}].discountValue`, null)
        form.setFieldValue(`services[${id}].discountAmount`, 0)
        form.setFieldValue(`services[${id}].discountRate`, 0)
        form.setFieldValue(
          `services[${id}].totalAmount`,
          values?.services[id]?.price || 0
        )
        form.setFieldValue(`services[${id}].giftCardAmount`, 0)
      }
    }
    if (name.endsWith('duration')) {
      const id = name?.slice(name?.indexOf('[') + 1, name?.lastIndexOf(']'))

      const endTime = getServiceHoursAndMinutes(
        getServiceDurationInMinutes(values.services[id].startTime?.id) +
          getServiceDurationInMinutes(value)
      )
      const endTimeUtc = moment(
        moment(values?.services[Number(id)]?.endTimeUtc)?.format('YYYY-MM-DD') +
          ' ' +
          endTime
      )

      form.setFieldValue(
        `services[${id}].durationMinutes`,
        getServiceDurationInMinutes(value)
      )
      form.setFieldValue(`services[${id}].endTime`, endTime)
      form.setFieldValue(
        `services[${id}].endTimeUtc`,
        endTimeUtc?.toISOString()
      )

      form.setFieldValue(
        'appointmentCopies',
        generateDefaultAppointmentCopies(values)
      )
    }
    if (name.endsWith('price')) {
      const index = name.substring(name.indexOf('[') + 1, name.lastIndexOf(']'))
      const id = values?.services[index]?.id

      const total =
        Math.round(
          (values?.services
            ?.filter(item => item?.id !== id)
            ?.reduce((acc, curr) => {
              return acc + (curr?.totalAmount || 0)
            }, 0) || 0 + Number.EPSILON) * 100
        ) /
          100 +
        value
      form.setFieldValue(`totalPrice`, total || 0)

      const servicesIncludeGiftCard = values?.services?.some(x => !!x?.giftCard)

      const sendPaymentRequestValue = values?.client
        ? hasPrepaymentsCredentials &&
          total > 0 &&
          hasPrepaymentsEnabled &&
          values?.client?.paymentRequired &&
          !servicesIncludeGiftCard
        : hasPrepaymentsCredentials &&
          total > 0 &&
          hasPrepaymentsEnabled &&
          !servicesIncludeGiftCard

      form.setFieldValue('sendPaymentRequest', sendPaymentRequestValue)
    }
    if (name.startsWith('appointmentCopies')) {
      const index = name.substring(name.indexOf('[') + 1, name.lastIndexOf(']'))
      const appointmentCopy = values?.appointmentCopies[index]

      if (name.endsWith('startTime')) {
        const newEndTime = moment(
          `${moment(appointmentCopy?.date)?.format(
            'YYYY-MM-DD'
          )} ${value?.title}`
        )
          .add(appointmentCopy?.durationMinutes, 'minutes')
          .format('HH:mm')
        form.setFieldValue(name?.replace('startTime', 'endTime'), {
          id: newEndTime,
          title: newEndTime,
        })

        form.setFieldValue(
          name?.replace('startTime', 'startTimeUtc'),
          moment(
            `${moment(appointmentCopy?.date)?.format(
              'YYYY-MM-DD'
            )} ${value?.title}`
          ).toISOString()
        )
        form.setFieldValue(
          name?.replace('startTime', 'endTimeUtc'),
          moment(
            `${moment(appointmentCopy?.date)?.format(
              'YYYY-MM-DD'
            )} ${newEndTime}`
          ).toISOString()
        )
      } else if (name.endsWith('date')) {
        form.setFieldValue(
          name?.replace('date', 'startTimeUtc'),
          moment(
            `${moment(value)?.format('YYYY-MM-DD')} ${appointmentCopy?.startTime
              ?.title}`
          ).toISOString()
        )

        form.setFieldValue(
          name?.replace('date', 'endTimeUtc'),
          moment(
            `${moment(value)?.format('YYYY-MM-DD')} ${appointmentCopy?.endTime
              ?.title}`
          ).toISOString()
        )
      }
    }

    if (name.endsWith('service')) {
      const selectedService = flattenedServices?.find(x => x.id === value)
      const index = name.substring(name.indexOf('[') + 1, name.lastIndexOf(']'))
      form.setFieldValue(
        `services[${index}].duration`,
        value
          ? getServiceDurationInMinutes(
              values?.services[index]?.startTime?.id?.toString()
            ) +
              getServiceDurationInMinutes(
                getServicesDuration(selectedService)
              ) >
            getServiceDurationInMinutes('24:00') -
              viewLocation?.timeSlotDuration!
            ? getServiceHoursAndMinutes(
                getServiceDurationInMinutes('24:00') -
                  viewLocation?.timeSlotDuration! -
                  getServiceDurationInMinutes(
                    values?.services[index]?.startTime?.id?.toString()
                  )
              )
            : getServicesDuration(selectedService)
          : '00:30'
      )

      form.setFieldValue(
        `services[${index}].durationMinutes`,
        value
          ? getServiceDurationInMinutes(
              values?.services[index]?.startTime?.id?.toString()
            ) +
              getServiceDurationInMinutes(
                getServicesDuration(selectedService)
              ) >
            getServiceDurationInMinutes('24:00') -
              viewLocation?.timeSlotDuration!
            ? getServiceDurationInMinutes('24:00') -
              viewLocation?.timeSlotDuration! -
              getServiceDurationInMinutes(
                values?.services[index]?.startTime?.id?.toString()
              )
            : getServiceDurationInMinutes(getServicesDuration(selectedService))
          : getServiceDurationInMinutes('00:30')
      )
      form.setFieldValue(`services[${index}].price`, selectedService?.price)
      form.setFieldValue(`services[${index}].currencyId`, org?.currency?.id)
      form.setFieldValue(
        `services[${index}].totalAmount`,
        selectedService?.price
      )
      form.setFieldValue(`services[${index}].discountRate`, 0)
      form.setFieldValue(`services[${index}].discountAmount`, 0)
      form.setFieldValue(`services[${index}].discountType`, 'percent')

      const filteredResources = resources?.filter(x =>
        some(x.professions, { id: selectedService?.resourceType?.id })
      )

      if (filteredResources?.length > 0) {
        setFilteredResources(filteredResources)

        const sortedResources = filteredResources
          ?.map(resource => {
            const { isInactive, isBusy } = getTimeSlotState(
              values?.date,
              values?.services[index]?.startTime?.id?.toString(),
              resource?.id
            )
            return {
              ...resource,
              isInactive: isInactive,
              isBusy: isBusy,
            }
          })
          ?.sort((a, b) => (a?.isBusy as any) - (b?.isBusy as any))
          ?.sort((a, b) => (a?.isInactive as any) - (b?.isInactive as any))

        const availableResource = sortedResources?.find(
          resource => !resource?.isInactive && !resource?.isBusy
        )

        if (sortedResources?.length >= 1) {
          form.setFieldValue(
            `services[${index}].resourceType`,
            !availableResource ? sortedResources[0].id : availableResource?.id
          )
          form.setFieldValue(
            `services[${index}].resourceId`,
            !availableResource ? sortedResources[0].id : availableResource?.id
          )
        }
      } else {
        form.setFieldValue(`services[${index}].resourceId`, null)
        form.setFieldValue(`services[${index}].resource`, null)
      }

      form.setFieldValue(
        'appointmentCopies',
        generateDefaultAppointmentCopies(values)
      )
    } else if (name === 'cancelStatus') {
      if (value?.id === 'conclude') {
        setActiveForm('status')
        setAppointmentStatus('conclude')
      } else if (value?.id === 'cancel') {
        setActiveForm('status')
        setAppointmentStatus('cancel')
      } else if (value?.id === 'confirm') {
        setActiveForm('status')
        setAppointmentStatus('backToConfirm')
      } else if (value?.id === 'confirmBooking') {
        setActiveForm('status')
        setAppointmentStatus('confirmBooking')
      } else if (value?.id === 'cancelBooking') {
        setActiveForm('status')
        setAppointmentStatus('cancelBooking')
      } else if (value?.id === 'sendPaymentNotification')
        setActiveForm('paymentNotification')
    }
  }
  //endregion
  const trpcContext = trpc.useContext()

  const updateGroupBookingNotesMutation =
    trpc.groupBooking_updateNotes.useMutation()

  //region MUTATIONS
  const [clientAddMutation, { loading: loadingAddClient }] =
    useClientsAddMutation()

  const [updateClient, { loading: loadingUpdateClient }] =
    useClientUpdateMutation()

  const [newService, { loading: loadingAddService }] =
    useMutateService(locationId)

  const [inviteUser] = useInviteUser()

  const createReminderMutation = trpc.reminder_create.useMutation()
  const updateProcessingStatusMutation =
    trpc.appointment_updateProcessingStatus.useMutation()

  const metadata = {
    time: moment().format('DD.MM.YYYY HH:mm'),
    orgId,
    locationId,
  }

  async function createClient(values) {
    try {
      setIsBusy(true)

      if (isNewClient) {
        const clientResult = await clientAddMutation({
          variables: {
            input: {
              firstName: values.firstName?.trim(),
              lastName: values.lastName?.trim() || '',
              fullName:
                values.firstName?.trim() +
                ' ' +
                (values.lastName?.trim() || ''),
              email: values.email ? values.email?.trim() : '',
              countryId: values.country?.id,
              address: values.address,
              zipCode: values.zipCode,
              language: org?.language || 'hr',
              city: values.city,
              comment: values.comment,
              mobilePhone: formatPhone(values.mobilePhone),
              gender: values.gender,
              identifier: values.identifier,
              vatId: values.vatId,
              birthDate: values.birthDate
                ? moment(values.birthDate).format('YYYY-MM-DD')
                : null,
              sendReminderEmail: values?.sendReminderEmail,
              sendReminderSms: values?.sendReminderSms,
              sendBookingEmail: true,
              sendNewsEmail: true,
              sendHelpEmail: true,
              paymentRequired: values?.paymentRequired,
            },
          },
          refetchQueries: [
            {
              query: searchClients,
              variables: {
                pageNumber: 1,
                pageSize: 50,
                searchTerm: '',
                sortBy: clientsSortBy,
              },
            },
          ],
        })
        if (values?.sendInviteViaEMail) {
          await inviteUser({
            variables: {
              input: {
                firstName: values.firstName?.trim(),
                lastName: values.lastName?.trim() || '',
                email: values.email,
                gender: values.gender || 'Unspecified',
                mobilePhone: formatPhone(values.mobilePhone),
                role: 'CLIENT',
                clientId: clientResult?.data?.clients?.add?.payload?.id!,
              },
            },
          })
        }

        if (!clientResult?.errors) {
          analyticsTrack('New client', {
            ...metadata,
            firstName: values.firstName,
            lastName: values.lastName || '',
            email: values.email,
            country: values.country?.id,
            address: values.address,
            zipCode: values.zipCode,
            city: values.city,
            comment: values.comment,
            gender: values.gender,
            identifier: values.identifier,
            vatId: values.vatId,
            birthDate: values.birthDate,
            mobilePhone: formatPhone(values.mobilePhone),
          })

          // @ts-ignore
          // @ts-ignore
          appServices.toast.success(
            (t('translation.AppointmentModal.toast-newClientEntered'),
            {
              firstName: values.firstName,
              lastName: values.lastName || '',
            })
          )
          return clientResult?.data?.clients?.add?.payload
        } else {
          appServices.toast.danger(
            t('translation.AppointmentModal.toast-newClientError')
          )
        }
      } else {
        let clientResult = await updateClient({
          variables: {
            input: {
              id: values.client?.id,
              firstName: values.firstName?.trim(),
              lastName: values.lastName?.trim() || '',
              fullName:
                values.firstName?.trim() +
                ' ' +
                (values.lastName?.trim() || ''),
              email: values.email ? values.email?.trim() : '',
              countryId: values.country?.id,
              address: values.address,
              zipCode: values.zipCode,
              city: values.city,
              comment: values.comment,
              gender: values.gender,
              mobilePhone: formatPhone(values.mobilePhone),
              identifier: values.identifier,
              vatId: values.vatId,
              birthDate: values.birthDate
                ? moment(values.birthDate).format('YYYY-MM-DD')
                : null,
              sendReminderEmail: values?.sendReminderEmail,
              sendReminderSms: values?.sendReminderSms,
              paymentRequired: values?.paymentRequired,
            },
          },
          refetchQueries: [
            {
              query: searchClients,
              variables: {
                pageNumber: 1,
                pageSize: 50,
                searchTerm: '',
                sortBy: clientsSortBy,
              },
            },
          ],
        })

        if (clientResult) {
          analyticsTrack('Updated client', {
            ...metadata,
            firstName: values.firstName,
            lastName: values.lastName || '',
            email: values.email,
            address: values.address,
            zipCode: values.zipCode,
            city: values.city,
            comment: values.comment,
            gender: values.gender,
            mobilePhone: formatPhone(values.mobilePhone),
          })

          if (!isNew) refetchAppointment()
          appServices.toast.success(
            (t('translation.EditClient.toast-editClient'),
            {
              firstName: values.firstName,
              lastName: values.lastName || '',
            })
          )
          return clientResult?.data?.clients?.update?.payload
        }
      }
    } finally {
      setIsBusy(false)
    }
  }

  async function createService(values) {
    try {
      setIsBusy(true)
      const input = {
        color: values.color,
        description: values.description,
        name: values.name,
        bookingAllowed: hasOnlineBooking ? values.bookingAllowed : false,
        hasBookingConfirmation: values.hasBookingConfirmation,
        price: values.price
          ? parseFloat(values.price?.toString().replace(',', '.'))
          : parseFloat('0'),
        durationMinutes: getServiceDurationInMinutes(values.durationMinutes),
        netDurationMinutes: Number(values.netDurationMinutes),
        serviceTypeId: values.type?.id,
        resources: values.resources?.map(x => x.id),
        professionId: values.profession?.id,
        locationId: locationId.toString(),
        resourceTypeId: values.resourceType?.id,
        vatRate: values.vatRate
          ? parseFloat((values.vatRate / 100).toString())
          : parseFloat('0'),
      }
      let serviceResult = await newService({
        variables: { input },
        refetchQueries: [
          {
            query: queryServiceTypes,
            variables: { locationId },
          },
        ],
      })

      if (serviceResult) {
        analyticsTrack('New service', {
          ...metadata,
          color: values.color,
          description: values.description,
          name: values.name,
        })

        appServices.toast.success(
          t('translation.AppointmentModal.toast-serviceCreated')
        )
        return serviceResult.data.services.add.payload
      }
    } finally {
      setIsBusy(false)
    }
  }

  const [cancelBookingMutation] = useCalendarCancelBookingMutation()

  const [appointmentCancelMutation] = useAppointmentCancelMutation()

  const [confirmBookingMutation] = useCalendarConfirmBookingMutation()
  const [completeAppointmentMutation, { loading: loadingCompleteAppointment }] =
    useCalendarCompleteAppointmentMutation()
  const [confirmAppointmentMutation, { loading: loadingConfirmAppointment }] =
    useCalendarConfirmAppointmentMutation()
  const [resendPaymentNotificationMutation] =
    usePrepaymentsResendPaymentNotificationMutation()
  const [sendPaymentNotificationMutation] =
    usePrepaymentsSendPaymentNotificationV2Mutation()

  async function cancelBooking(args: {
    reason: string
    message: string
    sendEmail: boolean
    sendSms: boolean
    shouldRefundPaymentRequest: boolean
  }) {
    try {
      setIsBusy(true)
      const {
        reason,
        message,
        sendEmail,
        sendSms,
        shouldRefundPaymentRequest,
      } = args
      let results = await cancelBookingMutation({
        variables: {
          input: {
            id: appointment!.id,
            reason,
            message,
            sendEmail,
            sendSms,
            shouldRefundPaymentRequest: !!shouldRefundPaymentRequest,
          },
        },
      })
      trpcContext.calendar_getEntries.invalidate()
      if (!results.errors) {
        goBack()
        analyticsTrack('Request canceled', metadata)
        appServices.toast.success(
          t('translation.AppointmentModal.toast-bookingCanceled')
        )
      } else {
        appServices.toast.danger(results?.errors?.[0]?.message)
      }
    } finally {
      setIsBusy(false)
    }
  }

  async function cancelAppointment(args: {
    reason: AppointmentCancellationEnum
    cancelNotes: string
    sendEmail: boolean
    sendSms: boolean
    shouldRefundPaymentRequest: boolean
    clientId: string
  }) {
    try {
      const {
        reason,
        cancelNotes,
        sendEmail,
        sendSms,
        shouldRefundPaymentRequest,
        clientId,
      } = args
      setIsBusy(true)
      let results = await appointmentCancelMutation({
        variables: {
          input: {
            id: BigInt(appointment!.id),
            reason,
            cancelNotes,
            sendEmail,
            sendSms,
            shouldRefundPaymentRequest,
          },
        },
        refetchQueries: [
          {
            query: ClientGetBookingsDocument,
            variables: { clientId: clientId },
          },
          { query: CalendarGetEntriesDocument },
        ],
        update: appointmentCancelUpdateCache,
      })
      if (!results.errors) {
        goBack()
        analyticsTrack('Request canceled', metadata)
        appServices.toast.success(
          t('translation.AppointmentModal.toast-rezervationCanceled')
        )
      } else {
        appServices.toast.danger(results?.errors?.[0]?.message)
      }
    } finally {
      setIsBusy(false)
    }
  }

  async function confirmBooking(args: {
    values: any
    message: string
    sendEmail: boolean
    sendSms: boolean
  }) {
    try {
      setIsBusy(true)
      const { values, message, sendEmail, sendSms } = args
      let results = await confirmBookingMutation({
        variables: {
          input: {
            id: values?.id,
            message: message,
            sendEmail,
            sendSms,
          },
        },
        refetchQueries: [
          {
            query: ClientGetBookingsDocument,
            variables: { clientId: values?.client?.id },
          },
        ],
      })

      if (!results.errors) {
        goBack()
        analyticsTrack('Request confirmed', metadata)

        appServices.toast.success(
          t('translation.AppointmentModal.toast-bookingAccepted')
        )
      } else {
        appServices.toast.danger(results?.errors?.[0]?.message)
      }
    } finally {
      setIsBusy(false)
    }
  }

  async function concludeAppointment(values, message) {
    try {
      setIsBusy(true)
      let results = await completeAppointmentMutation({
        variables: {
          id: values?.id,
          message,
          sendReviewRequestSms: values.sendReviewRequestSms,
          sendReviewRequestEmail: values.sendReviewRequestEmail,
        },
        refetchQueries: [
          {
            query: ClientGetBookingsDocument,
            variables: { clientId: values?.client?.id },
          },
        ],
      })
      if (!results.errors) {
        goBack()
        analyticsTrack('Appointment concluded', metadata)
        appServices.toast.success(
          t('translation.AppointmentModal.concludeAppointment')
        )
      } else appServices.toast.danger(results?.errors?.[0]?.message)
    } finally {
      setIsBusy(false)
    }
  }

  async function backToConfirmAppointment(values, message, sendEmail, reason) {
    try {
      setIsBusy(true)
      let results = await confirmAppointmentMutation({
        variables: {
          resourceBookingId: values?.id,
          appointmentId: values?.id,
          message,
          sendEmail: reason === 'CLIENT_NO_SHOW' ? false : sendEmail,
        },
        refetchQueries: [
          {
            query: ClientGetBookingsDocument,
            variables: { clientId: values?.client?.id },
          },
        ],
      })
      if (!results.errors) {
        goBack()
        analyticsTrack('Appointment confirmed', metadata)
        appServices.toast.success(
          t('translation.AppointmentModal.confirmAppointment')
        )
      } else appServices.toast.danger(results?.errors?.[0]?.message)
    } finally {
      setIsBusy(false)
    }
  }

  function goBack() {
    isDashboard ? navigateTo.dashboard({}) : handleOnCloseEvent()
  }

  const sendPaymentNotification = async (values, showSuccessToast = true) => {
    try {
      setIsBusy(true)
      if (!values?.id) return

      const results =
        !values?.posPaymentRequest ||
        values?.posPaymentRequest?.status === 'CANCELED'
          ? await sendPaymentNotificationMutation({
              variables: {
                id: values?.id,
                cancelAppointmentOnExpiredPaymentRequest:
                  values?.cancelAppointmentOnExpiredPaymentRequest,
              },
            })
          : await resendPaymentNotificationMutation({
              variables: {
                id: values?.id,
              },
            })

      if (!results.errors) {
        await refetchAppointment({ appointmentId: values?.id })
        if (showSuccessToast) {
          appServices.toast.success(
            t('translation.AppointmentModal.paymentRequest-sent')
          )
        }
      } else appServices.toast.danger(results?.errors?.[0]?.message)
    } catch (err) {
      console.log(err)
    } finally {
      setIsBusy(false)
    }
  }

  async function addMultipleAppointments(values) {
    const valuesArray: any[] = []
    const endTime = getServiceWithLatestEnding(values?.services)

    const sortedServices = sortBy(values?.services, 'startTime.id')
    const firstService = sortedServices[0]
    const firstServiceStartTimeUtc = moment(
      `${moment(values?.date)?.format('YYYY-MM-DD')} ${
        firstService.startTime.id
      }`
    ).toISOString()

    const startTimeOffsetsFromFirstService = sortedServices?.map(service => {
      const serviceStartTimeUtc = moment(
        `${moment(values?.date)?.format('YYYY-MM-DD')} ${service.startTime.id}`
      ).toISOString()

      return {
        offsetMinutes: moment(serviceStartTimeUtc).diff(
          moment(firstServiceStartTimeUtc),
          'minutes'
        ),
      }
    })

    for (let appCopy of values?.appointmentCopies) {
      const newStartTimeUtc = appCopy?.startTimeUtc

      const services = sortedServices?.map((ser, idx) => {
        const startTimeUtc = moment(newStartTimeUtc)
          .add(startTimeOffsetsFromFirstService[idx]?.offsetMinutes, 'minutes')
          .toISOString()
        const startTime = moment(startTimeUtc).format('HH:mm')

        const endTimeUtc = moment(startTimeUtc)
          .add(ser.durationMinutes)
          .toISOString()
        const endTime = moment(endTimeUtc).format('HH:mm')

        return {
          ...ser,
          startTimeUtc,
          startTime: { id: startTime, title: startTime },
          endTimeUtc,
          endTime,
        }
      })

      valuesArray.push({
        ...values,
        date: appCopy.date,
        services,
        endTime,
      })
    }

    if (!isNew) {
      // in edit first row is only presentational to see what appointment is copied so we dont want to recreate it
      valuesArray.shift()
    }
    if (!valuesArray.length) return

    const shouldSendPaymentRequest =
      valuesArray?.[0]?.appointmentCopySendPaymentRequest &&
      hasPrepaymentsCredentials &&
      !!valuesArray?.[0]?.services?.filter(x => x?.service).length &&
      valuesArray?.[0]?.client?.id

    const errors: any[] = []
    const allAppointmentServices: AppointmentBookMutation['appointment_book'] =
      []

    for (let values of valuesArray) {
      let results = await appointmentBookMutation({
        variables: {
          input: {
            clientId: parseInt(values?.client?.id),
            notes: values?.internalNote,
            bookingCopiesRef: values?.bookingCopiesRef,
            messageToClient: values?.sendAppointmentEmail
              ? values?.messageToClient
              : null,
            services: values.services?.map(e => {
              return {
                serviceId: e.service ? parseInt(e.service) : null,
                employeeId: parseInt(e.employee),
                resourceId: parseInt(e.resourceId),
                date: values.date,
                startTime: e.startTime?.id,
                durationMinutes: getServiceDurationInMinutes(e.duration),
                discountRate: e.discountRate,
                totalAmount:
                  Math.round((e.totalAmount + Number.EPSILON) * 100) / 100,
                discountType: e.discountType,
                discountAmount:
                  Math.round((e.discountAmount + Number.EPSILON) * 100) / 100,
              }
            }),
            sendAppointmentEmail: shouldSendPaymentRequest
              ? false
              : values.appointmentCopySendAppointmentEmail,
            /* sendAppointmentSms: shouldSendPaymentRequest
              ? false
              : values?.sendAppointmentSms, */
            sendAppointmentSms: false,
          },
        },
        update: appointmentBookMutationUpdateCache,
      })

      if (!results.errors?.length) {
        analyticsTrack('New appointment', metadata)
        allAppointmentServices.push(...(results?.data?.appointment_book || []))

        if (shouldSendPaymentRequest) {
          sendPaymentNotification(
            {
              id: results?.data?.appointment_book?.[0].resourceBookingId,
              cancelAppointmentOnExpiredPaymentRequest:
                valuesArray?.[0]
                  ?.appointmentCopyCancelAppointmentOnExpiredPaymentRequest,
            },
            false
          )
        }
      } else {
        errors.push(...results.errors)
      }
    }

    if (!errors.length) {
      appServices.toast.success(
        valuesArray?.length > 1
          ? t('translation.Appointment.multiple-appointments-success_other', {
              count: valuesArray?.length,
            })
          : t('translation.AppointmentModal.toast-bookingSuccess')
      )
      const sortedAllServices = sortBy(allAppointmentServices, 'startTimeUtc')
      const first = sortedAllServices[0]
      trpcContext.calendar_getEntries.invalidate()
      store.closeAppointmentModal()
      navigateTo.editAppointmentInDay({
        date: getISODate(first.startTimeUtc),
        appointmentId: first.resourceBookingId,
        view,
        selectedHour: first?.startTime?.split(':')[0],
        selectedMinute: first?.endTime?.split(':')[0],
      })
    }
  }

  async function addAppointment(values) {
    const shouldSendPaymentRequest =
      values?.sendPaymentRequest &&
      hasPrepaymentsCredentials &&
      !!values?.services?.filter(x => x?.service).length &&
      values?.client?.id

    let results = await appointmentBookMutation({
      variables: {
        input: {
          clientId: parseInt(values?.client?.id),
          notes: values?.internalNote,
          messageToClient: values?.sendAppointmentEmail
            ? values?.messageToClient
            : null,
          services: values.services?.map(e => {
            return {
              serviceId: e.service ? parseInt(e.service) : null,
              employeeId: parseInt(e.employee),
              resourceId: parseInt(e.resourceId),
              giftCardId: e.giftCard?.id ? BigInt(e.giftCard?.id) : null,
              giftCardAmount:
                Math.round((e.giftCardAmount + Number.EPSILON) * 100) / 100,
              date: values.date,
              startTime: e.startTime?.id,
              durationMinutes: getServiceDurationInMinutes(e.duration),
              discountRate: parseFloat(e.discountRate.toString()),
              totalAmount:
                Math.round((e.totalAmount + Number.EPSILON) * 100) / 100,
              discountType: e.discountType,
              discountAmount:
                Math.round((e.discountAmount + Number.EPSILON) * 100) / 100,
              price: Math.round((e.price + Number.EPSILON) * 100) / 100,
            }
          }),
          sendAppointmentEmail: shouldSendPaymentRequest
            ? false
            : values.sendAppointmentEmail,
          sendAppointmentSms: shouldSendPaymentRequest
            ? false
            : values?.sendAppointmentSms,
        },
      },
      update: appointmentBookMutationUpdateCache,
    })

    trpcContext.calendar_getEntries.invalidate()

    await apolloClient.query({
      query: ClientSearchDocument,
      variables: {
        searchTerm: '',
        pageNumber: 1,
        pageSize: 50,
        sortBy: localStorage.getItem('clientsSortBy') || 'id_desc',
      },
    })

    if (!results.errors) {
      //goBack()
      analyticsTrack('New appointment', metadata)
      handleOnCloseEvent()

      if (shouldSendPaymentRequest) {
        sendPaymentNotification({
          id: results?.data?.appointment_book?.[0].resourceBookingId,
          cancelAppointmentOnExpiredPaymentRequest:
            values?.cancelAppointmentOnExpiredPaymentRequest,
        })
      } else {
        appServices.toast.success(
          t('translation.AppointmentModal.toast-bookingSuccess')
        )
      }
    } else {
      appServices.toast.danger(results?.errors?.[0]?.message)
    }
  }

  async function updateAppointment(
    values,
    dontShowToast = false,
    doGoBack = true
  ) {
    let results = await appointmentRescheduleMutation({
      variables: {
        input: {
          id: parseInt(values.id),
          clientId: parseInt(values?.client?.id),
          notes: values?.internalNote,
          bookingCopiesRef: values?.bookingCopiesRef,
          messageToClient: values?.sendAppointmentEmail
            ? values?.messageToClient
            : null,
          date: values.date,
          services: values.services?.map(e => {
            return {
              serviceId: e.service ? parseInt(e.service) : null,
              employeeId: parseInt(e.employee),
              resourceId: parseInt(e.resourceId),
              giftCardId: e.giftCard?.id ? BigInt(e.giftCard?.id) : null,
              giftCardAmount:
                Math.round((e.giftCardAmount + Number.EPSILON) * 100) / 100,
              date: values.date,
              startTime: e.startTime?.id,
              durationMinutes: getServiceDurationInMinutes(e.duration),
              discountRate: parseFloat(e.discountRate.toString()),
              totalAmount:
                Math.round((e.totalAmount + Number.EPSILON) * 100) / 100,
              discountType: e.discountType,
              discountAmount:
                Math.round((e.discountAmount + Number.EPSILON) * 100) / 100,
              price: Math.round((e.price + Number.EPSILON) * 100) / 100,
            }
          }),
          sendAppointmentEmail: values.sendAppointmentEmail,
          sendAppointmentSms: values.sendAppointmentSms,
          cancelAppointmentOnExpiredPaymentRequest:
            values?.appointment_cancelAppointmentOnExpiredPaymentRequest,
        },
      },
      update: appointmentRescheduleUpdateCache,
    })
    trpcContext.calendar_getEntries.invalidate()
    await apolloClient.query({
      query: ClientSearchDocument,
      variables: {
        searchTerm: '',
        pageNumber: 1,
        pageSize: 50,
        sortBy: localStorage.getItem('clientsSortBy') || 'id_desc',
      },
    })

    if (!results.errors) {
      if (doGoBack) goBack()
      if (!dontShowToast)
        appServices.toast.success(
          t('translation.AppointmentModal.toast-bookingChanged')
        )
    } else appServices.toast.danger(results?.errors?.[0]?.message)
  }

  async function updateGroupBookingNotes(
    values,
    dontShowToast = false,
    doGoBack = true
  ) {
    try {
      const result = await updateGroupBookingNotesMutation.mutateAsync({
        locationId: BigInt(locationId),
        notes: values?.internalNote,
        orgId,
        resourceBookingId: BigInt(values?.id),
      })

      await apolloClient.query({
        query: ClientGetAppointmentsFromResourceBookingsDocument,
        variables: {
          clientId: values?.client?.id,
        },
        fetchPolicy: 'network-only',
      })

      goBack()

      appServices.toast.success(
        t('translation.AppointmentModal.toast-bookingChanged')
      )
    } catch (err: any) {
      appServices.toast.danger(err.message || 'Došlo je do pogreške')
    }
  }
  async function createReminder(values) {
    try {
      const result = await createReminderMutation.mutateAsync({
        remindersConfigId: BigInt(values?.reminder?.reminderConfigId),
        reminderDate: moment(values?.reminder?.reminderDate).format(
          'YYYY-MM-DD'
        ),
        reminderTime: values?.reminder?.reminderTime,
        clientId: BigInt(values.client?.id),
        messageToClient: values?.reminder?.messageToClient || '',
      })
      trpcContext.reminders_get.invalidate()
      appServices.toast.success('Podsjetnik uspješno kreiran')
    } catch (err) {
      appServices.toast.danger('Došlo je do pogreške kod kreiranja podsjetnika')
    }
  }
  async function onProcessingStatusUpdate(status: string) {
    try {
      if (status === 'CONCLUDED') {
        handleStatusUpdate('conclude')
        return
      }
      const result = await updateProcessingStatusMutation.mutateAsync({
        resourceBookingId: eventId!,
        processingStatus: status,
      })

      const appointment = await refetchAppointment()
      formRef.current?.setFieldValue(
        'processingStatus',
        appointment?.data?.appointment?.find?.processingStatus
      )
      formRef.current?.resetForm(formRef.current?.values)

      appServices.toast.success('Status obrade uspješno ažuriran')
    } catch (err) {
      appServices.toast.danger(
        (err as any).message ||
          'Došlo je do pogreške kod ažuriranja statusa obrade'
      )
    }
  }

  //endregion

  //region SUBMIT
  const handleSubmit = async (values, form) => {
    setIsBusy(true)
    if (isGroupAppointmentSlot && activeForm === 'appointment') {
      await updateGroupBookingNotes(values)
      setIsBusy(false)
      return
    }
    const endTime = getServiceWithLatestEnding(values?.services)
    if (activeForm === 'appointment') {
      isNew
        ? await addAppointment({ ...values, endTime })
        : await updateAppointment({ ...values, endTime })
    } else if (activeForm === 'client') {
      const val = values
      const client = await createClient(values)
      // for some reason form.setFieldValue('client', client) doesn't work properly
      formRef.current?.setFieldValue('client', client)
      formRef.current?.setFieldValue('services', val?.services)
      formRef.current?.setFieldValue('internalNote', val?.internalNote)
      formRef.current?.setFieldValue(
        'sendAppointmentEmail',
        val?.sendAppointmentEmail
      )
      if (!viewLocation?.sendAppointmentEmailsToClients) {
        form.setFieldValue('sendAppointmentEmail', false)
      } else {
        formRef.current?.setFieldValue(
          'sendAppointmentEmail',
          val?.sendAppointmentEmail
        )
      }
      formRef.current?.setFieldValue(
        'sendPaymentRequest',
        val?.sendPaymentRequest
      )

      setActiveForm('appointment')
    } else if (activeForm === 'status') {
      if (appointmentStatus === 'cancelBooking' && eventId) {
        return cancelBooking({
          reason: values.cancelReason,
          message: values.cancelNotes,
          sendEmail: values.sendEmail,
          sendSms: values.sendSms,
          shouldRefundPaymentRequest: values.shouldRefundPaymentRequest,
        })
      } else if (appointmentStatus === 'cancel') {
        return cancelAppointment({
          reason: values.cancelReason,
          cancelNotes: values.cancelNotes,
          sendEmail: values.sendEmail,
          sendSms: values.sendSms,
          shouldRefundPaymentRequest: values.shouldRefundPaymentRequest,
          clientId: values.client?.id,
        })
      } else if (appointmentStatus === 'confirmBooking' && eventId) {
        await updateAppointment({ ...values, endTime }, true)
        return confirmBooking({
          values,
          message: values.cancelNotes,
          sendEmail: values.sendEmail,
          sendSms: values.sendSms,
        })
      } else if (appointmentStatus === 'conclude' && eventId) {
        return concludeAppointment(values, values.cancelNotes)
      } else if (appointmentStatus === 'backToConfirm') {
        return backToConfirmAppointment(
          values,
          values.cancelNotes,
          values.sendEmail,
          values?.reason
        )
      }
    } else if (activeForm === 'paymentNotification') {
      if (appointment?.status !== 'DRAFT')
        await updateAppointment({ ...values, endTime }, true)

      sendPaymentNotification(values)
      setActiveForm('appointment')
    } else if (activeForm === 'copyAppointment') {
      const bookingCopiesRef = values?.bookingCopiesRef
        ? values?.bookingCopiesRef
        : nanoid()

      if (!isNew) {
        await updateAppointment({ ...values, bookingCopiesRef }, true, false)
      }

      await addMultipleAppointments({ ...values, bookingCopiesRef })

      setActiveForm('appointment')
    } else if (activeForm === 'reminder') {
      await createReminder(values)
      if (isMobile) {
        setActiveForm('reminder_list')
      } else {
        setActiveForm('appointment')
      }
      // helper activeForm for displaying client reminders from appointment
    } else if (activeForm === 'reminder_list') {
      await updateAppointment({ ...values, endTime })
      setActiveForm('appointment')
    } else {
      const service = await createService(values)

      form.setFieldValue('name', null)
      form.setFieldValue('type', null)
      form.setFieldValue('price', null)
      form.setFieldValue('duration', '00:30')
      form.setFieldValue('durationMinutes', 30)
      form.setFieldValue('color', selectedTheme.colors.primary)
      form.setFieldValue('bookingAllowed', true)
      form.setFieldValue('profession', null)
      form.setFieldValue('description', null)
      form.setFieldValue(
        'vatRate',
        getOrganizationData?.vatStatus === 'Y' ? 25 : 0
      )
      form.setFieldValue('netDurationMinutes', 30)
      form.setFieldValue('hasBookingConfirmation', true)
      form.setFieldValue('resourceType', null)
      form.setFieldValue(
        'services',
        values?.services?.map(x => {
          return x?.isEdit
            ? {
                ...x,
                durationMinutes: parseInt(service.durationMinutes),
                duration: getServiceHoursAndMinutes(service.durationMinutes),
                service: service?.id,
                price: service?.price,
                discountRate: 0,
                discountAmount: 0,
                discountValue: 0,
                discountType: 'percent',
                currencyId: org?.currency?.id,
                totalAmount: service?.price,
              }
            : x
        })
      )
      setActiveForm('appointment')
    }
    setIsBusy(false)
  }
  //endregion

  const resetFormValues = () => {
    var isChanged = false
    if (
      moment(initialValues?.date).format(
        t('regionalFormats.date.momentFormatDate')
      ) !==
        moment(formRef.current?.values?.date).format(
          t('regionalFormats.date.momentFormatDate')
        ) ||
      initialValues?.client?.id !== formRef.current?.values?.client?.id ||
      initialValues?.endTime !== formRef.current?.values?.endTime ||
      initialValues?.startTime !== formRef.current?.values?.startTime ||
      initialValues?.notes !== formRef.current?.values?.notes ||
      initialValues?.attachments !== formRef.current?.values?.attachments ||
      initialValues?.services?.length !==
        formRef.current?.values?.services?.length
    )
      isChanged = true
    else if (!!initialValues?.services?.length) {
      for (let index = 0; index < initialValues?.services?.length; index++) {
        if (
          initialValues?.services[index]?.employee !==
            formRef.current?.values?.services[index]?.employee ||
          initialValues?.services[index]?.endTime !==
            formRef.current?.values?.services[index]?.endTime ||
          initialValues?.services[index]?.resourceId !==
            formRef.current?.values?.services[index]?.resourceId ||
          initialValues?.services[index]?.service !==
            formRef.current?.values?.services[index]?.service ||
          initialValues?.services[index]?.startTime?.id !==
            formRef.current?.values?.services[index]?.startTime?.id
        ) {
          isChanged = true
          return
        }
      }
    }

    return isNew || isChanged
  }

  const getSaveButtonLabel = () => {
    return activeForm === 'paymentNotification'
      ? !hasPrepaymentsCredentials
        ? t('translation.AppointmentModal.button-goToPrepayments')
        : formRef.current?.values.posPaymentRequest?.status ===
          'PENDING_PAYMENT'
        ? t('translation.AppointmentModal.resendPaymentNotification')
        : t('translation.AppointmentModal.sendPaymentNotification')
      : t('translation.AppointmentModal.button-save')
  }

  const shouldShowSendNotificationEmailCheckBox = values => {
    if (isNew)
      return values?.sendPaymentRequest ? false : !!values.client?.email

    return (
      values.client?.email &&
      (values?.services?.sort((a, b) =>
        moment(
          moment().format('YYYY-MM-DD').toString() +
            ' ' +
            a?.startTime?.id.toString()
        ).diff(
          moment(
            moment().format('YYYY-MM-DD').toString() +
              ' ' +
              b?.startTime?.id.toString()
          )
        )
      )[0]?.startTime?.id !== appointment?.startTime ||
        getISODate(values?.date) !== getISODate(appointment?.date))
    )
  }

  const shouldShowSendNotificationSmsCheckBox = values => {
    if (isNew)
      return values?.sendPaymentRequest ? false : !!values.client?.mobilePhone

    return (
      values.client?.mobilePhone &&
      (values?.services?.sort((a, b) =>
        moment(
          moment().format('YYYY-MM-DD').toString() +
            ' ' +
            a?.startTime?.id.toString()
        ).diff(
          moment(
            moment().format('YYYY-MM-DD').toString() +
              ' ' +
              b?.startTime?.id.toString()
          )
        )
      )[0]?.startTime?.id !== appointment?.startTime ||
        getISODate(values?.date) !== getISODate(appointment?.date))
    )
  }

  const handleStatusUpdate = type => {
    // tu
    if (type === 'conclude') {
      setActiveForm('status')
      setAppointmentStatus('conclude')
    } else if (type === 'cancel') {
      setActiveForm('status')
      setAppointmentStatus('cancel')
    } else if (type === 'confirm') {
      setActiveForm('status')
      setAppointmentStatus('backToConfirm')
    } else if (type === 'confirmBooking') {
      setActiveForm('status')
      setAppointmentStatus('confirmBooking')
    } else if (type === 'cancelBooking') {
      setActiveForm('status')
      setAppointmentStatus('cancelBooking')
    } else if (type === 'sendPaymentNotification')
      setActiveForm('paymentNotification')
  }

  const { selectClient, addItem, resetReceipt, setReceiptResourceBookingId } =
    PosReceiptStore

  const [selectedReceipt, setSelectedReceipt] = useState<string | null>(null)
  const [isIssueReceipt, setIsIssueReceipt] = useState(false)

  const handleIssueReceipt = async values => {
    setIsBusy(true)
    await updateAppointment(values, true, false)

    setPreparingForIssueReceipt(true)
    resetReceipt()
    setSelectedReceipt(null)

    if (appointment?.client) {
      selectClient(appointment?.client)
    }
    setReceiptResourceBookingId(appointment?.id)
    const updatedAppointmentData = await refetchAppointment()
    const updatedServices =
      updatedAppointmentData?.data?.appointment?.find?.services

    for (const service of updatedServices?.filter(
      service => service?.service
    )!) {
      addItem({
        resourceBookingServiceId: service?.id,
        price: service?.price || 0,
        itemDescription: service?.service?.name || '',
        serviceId: service?.service?.id,
        vatRate: service?.service?.vatRate || 0,
        serviceType: service?.service?.type?.name,
        finalPrice: service?.totalAmount,
        total: service?.totalAmount,
        discountType: service?.discountType,
        discountAmount: service?.discountAmount,
        discountRate: service?.discountRate,
      })
    }

    // adding giftcards items if any
    for (const service of updatedServices?.filter(
      service => service?.service
    )!) {
      if (service?.giftCardId) {
        addItem({
          price: -Number(service?.giftCardAmount),
          itemDescription: `${service?.giftCard?.name} (${service?.giftCard?.code})`,
          giftCardId: service?.giftCardId,
          vatRate: service?.service?.vatRate || 0,
          quantity:
            service?.giftCard?.giftCardType === 'VOUCHER'
              ? 1
              : roundNumberWithTwoDecimals(
                  service?.giftCardAmount! / service?.giftCard?.value!
                ),
          finalPrice: -Number(service?.giftCardAmount),
          total: -Number(service?.giftCardAmount),
          discountType: 'percent',
          discountAmount: 0,
          discountRate: 0,
        })
      }
    }

    if (appointment?.posPaymentRequest?.status === 'PAID') {
      const prepaymentServiceId =
        viewLocationData?.location?.get?.prepaymentsConfig?.prepaymentServiceId
      const prepaymentServiceTypeRecord = services?.find(
        x => x?.services?.find(y => y.id === prepaymentServiceId)
      )

      const prepaymentServiceRecord =
        prepaymentServiceTypeRecord?.services?.find(
          z => z.id === prepaymentServiceId
        )

      if (prepaymentServiceRecord) {
        addItem({
          quantity: -1,
          discountAmount: 0,
          discountRate: 0,
          price: Number(appointment.posPaymentRequest.amount!),
          total: -Number(appointment.posPaymentRequest.amount!),
          finalPrice: -Number(appointment.posPaymentRequest.amount!),
          serviceId: prepaymentServiceRecord.id,
          paymentRequestId: appointment.posPaymentRequest.id,
          paymentRequestReceiptNo: appointment.posPaymentRequest.receiptNo,
          itemDescription: prepaymentServiceRecord.name,
          vatRate: prepaymentServiceRecord?.vatRate || 0,
        })
      }
    }
    refetchAppointment()
    setIsIssueReceipt(true)
    setPreparingForIssueReceipt(false)
    trpcContext.calendar_getEntries.invalidate()
    setIsBusy(false)
  }

  const issueReceiptButtonDisabled =
    journal?.status === 'CLOSED' ||
    !moment(getISODate(journal?.date)).isSame(getISODate(new Date())) ||
    !journal

  const isLoading =
    journalLoading ||
    loadingAppointment ||
    loadingResource ||
    serviceTypesData.loading ||
    loadingAddClient ||
    loadingUpdateClient ||
    loadingAddAppointment ||
    loadingLocation ||
    loadingOrgData ||
    loadingStripeConnectAccountData ||
    loadingCompleteAppointment ||
    loadingConfirmAppointment ||
    loadingEditEvent

  if (isIssueReceipt) {
    return (
      <NewReceipt
        setOpenNewReceiptModal={setIsIssueReceipt}
        isAppointment
        handleOnCloseEvent={handleOnCloseEvent}
        setIsIssueReceipt={setIsIssueReceipt}
        setSelectedReceipt={setSelectedReceipt}
        selectedReceipt={selectedReceipt}
      />
    )
  }
  return (
    <Container>
      <Modal>
        <ModalWrapper>
          <FormWrapper>
            {isLoading ? (
              <div style={{ minHeight: '500px' }}>
                <Loader isComponent />
              </div>
            ) : (
              <Form
                onValueChange={handleValueChange}
                initialValues={{
                  ...initialValues,
                  currencyId: getOrganizationData?.currency?.currencyCode || '',
                  description: '',
                  color: selectedTheme.colors.primary,
                  durationMinutes: 30,
                  netDurationMinutes: 30,
                  bookingAllowed: true,
                  hasBookingConfirmation: true,
                  vatRate: getOrganizationData?.vatStatus === 'Y' ? 25 : 0,
                  price: 0,
                  resources,
                }}
                validationSchema={
                  isGroupAppointmentSlot && activeForm === 'appointment'
                    ? undefined
                    : activeForm === 'appointment'
                    ? ServiceSchema(t)
                    : activeForm === 'client'
                    ? ClientSchema
                    : activeForm === 'service'
                    ? AddNewServiceSchema(t)
                    : activeForm === 'status' && appointmentStatus === 'cancel'
                    ? CancellationSchema(t)
                    : null
                }
                isInitialValid={true}
                onSubmit={handleSubmit}
              >
                {form => {
                  const { values, errors, setFieldValue, validateForm } = form

                  formRef.current = form

                  const statusOptions = getOptionStatus(
                    appointment,
                    hasPrepaymentsCredentials,
                    values?.totalPrice
                  )

                  const newTotalPrice =
                    Math.round(
                      (values?.services?.reduce((acc, curr) => {
                        return acc + (curr?.price || 0)
                      }, 0) || 0 + Number.EPSILON) * 100
                    ) / 100

                  const newTotalDiscount =
                    Math.round(
                      (values?.services?.reduce((acc, curr) => {
                        return acc + (curr.discountAmount || 0)
                      }, 0) || 0 + Number.EPSILON) * 100
                    ) / 100

                  const newTotalGiftCardDiscount =
                    Math.round(
                      (values?.services?.reduce((acc, curr) => {
                        return acc + (curr?.giftCardAmount || 0)
                      }, 0) || 0 + Number.EPSILON) * 100
                    ) / 100

                  const isPrepaymentDisabled =
                    !hasPrepaymentsCredentials ||
                    !values?.services?.filter(x => x?.service).length ||
                    newTotalPrice <= 0

                  const sendPaymentNotificationButton =
                    !!statusOptions?.find(
                      x => x?.id === 'sendPaymentNotification'
                    ) &&
                    values?.client?.email &&
                    !values?.services?.some(ser => ser?.giftCard)

                  setServiceMinDuration(
                    !formRef.current?.values?.services?.find(
                      x => x?.durationMinutes > 0
                    )
                  )

                  return activeForm === 'appointment' ? (
                    <>
                      <TabView
                        selectedTabIndex={selectedTabIndex}
                        setSelectedTabIndex={setSelectedTabIndex}
                        isSingleTab={false}
                        hasFooter={false}
                      >
                        <Tab
                          hasFooter={false}
                          title={
                            initialValues?.id
                              ? isMobile
                                ? t('translation.Appointment.termin')
                                : t(
                                    'translation.Appointment.title-view-appointment'
                                  )
                              : t('translation.AppointmentModal.addAppointment')
                          }
                        >
                          {isBusy && <Loader isComponent />}

                          <div className="py-3 px-4 lg:py-6 lg:px-8">
                            <Header
                              handleStatusUpdate={handleStatusUpdate}
                              setActiveForm={setActiveForm}
                              ref={calendarRef}
                              booking={{}}
                              status={values?.status}
                              client={values.client}
                              showStatusOptions={!isNew}
                              date={date => {
                                setFieldValue('date', date)
                                setSelectedDayId(date)
                              }}
                              onCopyAppointmentClick={() => {
                                setFieldValue(
                                  'appointmentCopies',
                                  generateDefaultAppointmentCopies(values)
                                )
                                setActiveForm('copyAppointment')
                              }}
                              selectedDayId={selectedDayId}
                              values={values}
                              options={statusOptions}
                              isNew={isNew}
                              timeSlotDuration={viewLocation?.timeSlotDuration!}
                              onProcessingStatusUpdate={
                                onProcessingStatusUpdate
                              }
                            />
                            <Row>
                              {/* {isClientDisabled &&
                              values?.services?.find(x => x?.giftCard) ? (
                                <div className="grow text-zoyya-accent5 text-sm mb-[5px]">
                                  <Warning size="smaller" />
                                  <span className="ml-[10px]">
                                    Ne možete mijenjati klijenta, ako imate
                                    pridružen poklon bon
                                  </span>
                                </div>
                              ) : null} */}

                              <ClientSelect
                                onItemSelected={client =>
                                  form.setFieldValue('client', client)
                                }
                                selectedItem={values.client}
                                disabled={
                                  !canEditAppointment ||
                                  values.status === 'canceled' ||
                                  values.status === 'DRAFT'
                                }
                                onNewClient={getOnNewClient(form)}
                                currency={org?.currency?.id || ''}
                                // onComponentClick={
                                //   values?.services?.find(x => x?.giftCard)
                                //     ? () => {
                                //         setIsClientDisabled(true)
                                //       }
                                //     : null
                                // }
                              />

                              {values?.client?.comment ? (
                                <p className="line-clamp-4 text-zoyya-secondaryDark text-sm">
                                  {values?.client?.comment}
                                </p>
                              ) : null}
                            </Row>
                            {isMobile && (
                              <p className="pt-8 text-[20px] font-medium">
                                {t('translation.Sidebar.label-services')}
                              </p>
                            )}

                            <ServicesRow>
                              <Service
                                setFieldValue={setFieldValue}
                                flattenedServices={flattenedServices}
                                isLoading={isLoading}
                                values={values}
                                services={services}
                                validateForm={validateForm}
                                getWorkHours={getWorkHours}
                                timeSlotDuration={
                                  viewLocation?.timeSlotDuration!
                                }
                                newEvent={newEvent}
                                assignToResource={assignToResource}
                                duration={durationOptions}
                                workingEmployees={workingEmployees}
                                onNewService={() => setActiveForm('service')}
                                employees={employees}
                                resources={resources}
                                workingResources={workingResources}
                                setServiceInEdit={setServiceInEdit}
                                totalServicesPrice={newTotalPrice}
                                totalServicesDiscount={newTotalDiscount}
                                totalServicesGiftCardDiscount={
                                  newTotalGiftCardDiscount
                                }
                                error={error}
                                setError={setError}
                                isMenuOpen={isMenuOpen}
                                setIsMenuOpen={setIsMenuOpen}
                              />
                            </ServicesRow>

                            {/*  */}
                            {isNew &&
                            values?.client?.email &&
                            !values?.services?.some(ser => ser?.giftCard) ? (
                              <StyledFieldRow style={{ marginTop: 0 }}>
                                <StyledFieldColumn>
                                  <FormField.Checkbox
                                    label={t(
                                      'translation.AppointmentModal.sendPaymentNotification'
                                    )}
                                    name="sendPaymentRequest"
                                    type={'checkbox'}
                                    disabled={isPrepaymentDisabled}
                                    helpText={t(
                                      'translation.AppointmentModal.sendPaymentNotification-hint'
                                    )}
                                  />
                                </StyledFieldColumn>

                                {values?.sendPaymentRequest &&
                                !isPrepaymentDisabled ? (
                                  <StyledFieldColumn>
                                    <FormField.Checkbox
                                      label={t(
                                        'translation.AppointmentModal.cancelBookingOnExpired'
                                      )}
                                      name="cancelAppointmentOnExpiredPaymentRequest"
                                      type={'checkbox'}
                                      helpText={t(
                                        'translation.AppointmentModal.cancelBookingOnExpiredTooltip'
                                      )}
                                    />
                                  </StyledFieldColumn>
                                ) : null}
                              </StyledFieldRow>
                            ) : null}

                            <StyledTextArea>
                              <FormField.TextArea
                                label={t(
                                  'translation.AppointmentModal.placeholderText'
                                )}
                                placeholder={t(
                                  'translation.Appointment.vidljivo-samo-zaposlenicima'
                                )}
                                readOnly={!canEditAppointment}
                                name="internalNote"
                                rows={2}
                              />
                            </StyledTextArea>
                            <div className="flex flex-col lg:flex-row">
                              {shouldShowSendNotificationEmailCheckBox(
                                values
                              ) ? (
                                <StyledFieldRow style={{ flex: 1 }}>
                                  <StyledFieldColumn>
                                    <FormField.Checkbox
                                      label={t(
                                        'translation.UpdateStatusModal.sendClientEmail'
                                      )}
                                      name="sendAppointmentEmail"
                                      readOnly={!canEditAppointment}
                                      type={'checkbox'}
                                      helpText={
                                        isNew
                                          ? t(
                                              'translation.UpdateStatusModal.sendEmailHintNew'
                                            )
                                          : t(
                                              'translation.UpdateStatusModal.sendEmailHint'
                                            )
                                      }
                                    />
                                  </StyledFieldColumn>
                                </StyledFieldRow>
                              ) : null}

                              {shouldShowSendNotificationSmsCheckBox(values) &&
                              !smsBalanceLoading ? (
                                <StyledFieldRow style={{ flex: 1 }}>
                                  <StyledFieldColumn>
                                    {smsBalance > 0 ? (
                                      <FormField.Checkbox
                                        label={t(
                                          'translation.UpdateStatusModal.sendClientSms'
                                        )}
                                        name="sendAppointmentSms"
                                        disabled={smsBalance <= 0}
                                        type={'checkbox'}
                                        helpText={
                                          isNew
                                            ? t(
                                                'translation.UpdateStatusModal.sendSmsHintNew'
                                              )
                                            : t(
                                                'translation.UpdateStatusModal.sendSmsHint'
                                              )
                                        }
                                      />
                                    ) : (
                                      <ToolTip
                                        label={t(
                                          'translation.Appointment.no-sms-available'
                                        )}
                                      >
                                        <FormField.Checkbox
                                          label={t(
                                            'translation.UpdateStatusModal.sentClientSms'
                                          )}
                                          name="sendAppointmentSms"
                                          disabled={smsBalance <= 0}
                                          type={'checkbox'}
                                          helpText={
                                            isNew
                                              ? t(
                                                  'translation.UpdateStatusModal.sendSmsHintNew'
                                                )
                                              : t(
                                                  'translation.UpdateStatusModal.sendSmsHint'
                                                )
                                          }
                                        />
                                      </ToolTip>
                                    )}
                                  </StyledFieldColumn>
                                </StyledFieldRow>
                              ) : null}
                            </div>
                            {!values?.sendPaymentRequest &&
                            shouldShowSendNotificationEmailCheckBox(values) &&
                            values?.sendAppointmentEmail ? (
                              <StyledTextArea>
                                <FormField.TextArea
                                  label={t(
                                    'translation.AppointmentModal.messageToClient'
                                  )}
                                  placeholder={
                                    isNew
                                      ? t(
                                          'translation.Appointment.vidljivo-klijentima-upis'
                                        )
                                      : t(
                                          'translation.Appointment.vidljivo-klijentima'
                                        )
                                  }
                                  name="messageToClient"
                                  readOnly={!canEditAppointment}
                                  rows={2}
                                />
                              </StyledTextArea>
                            ) : null}

                            {values.clientNote ? (
                              <div className="p-3 bg-zoyya-grayLighter rounded-md mt-3">
                                <label className="font-medium">
                                  {t(
                                    'translation.AppointmentModal.client-note'
                                  )}
                                </label>
                                <div>{values.clientNote}</div>
                              </div>
                            ) : null}

                            {!isNew &&
                            (values?.posPaymentRequest?.id ||
                              sendPaymentNotificationButton) ? (
                              <HeaderWrapper
                                style={{
                                  zIndex: 1,
                                  marginBottom: '0',
                                  marginTop: '20px',
                                  borderBottom: 'none',
                                }}
                                hasBorderBottom={false}
                                flexEnd={!!values?.posPaymentRequest?.id}
                                flexDirection={'row'}
                              >
                                {values?.posPaymentRequest?.id ? (
                                  <div
                                    className={`flex lg:block`}
                                    style={{
                                      color:
                                        values.id &&
                                        getStatusColor(
                                          values?.posPaymentRequest?.status,
                                          selectedTheme
                                        ),
                                    }}
                                  >
                                    {values.id &&
                                      getStatusLabel(
                                        values?.posPaymentRequest?.status,
                                        t,
                                        false,
                                        values.reason
                                      )}
                                  </div>
                                ) : null}
                                {canEditAppointment &&
                                sendPaymentNotificationButton ? (
                                  <Button
                                    onClick={() =>
                                      setActiveForm('paymentNotification')
                                    }
                                    buttonType={'primary'}
                                    name={'sendPaymentNotification'}
                                    label={t(
                                      'translation.AppointmentModal.sendPaymentNotification'
                                    )}
                                  />
                                ) : null}
                              </HeaderWrapper>
                            ) : null}

                            {!isNew &&
                            values?.posPaymentRequest?.id &&
                            values?.posPaymentRequest?.status ===
                              'PENDING_PAYMENT' &&
                            values?.status !== 'DRAFT' ? (
                              <StyledFieldRow style={{ marginTop: 0 }}>
                                <StyledFieldColumn>
                                  <FormField.Checkbox
                                    label={t(
                                      'translation.AppointmentModal.cancelBookingOnExpired'
                                    )}
                                    name="appointment_cancelAppointmentOnExpiredPaymentRequest"
                                    type={'checkbox'}
                                    helpText={t(
                                      'translation.AppointmentModal.cancelBookingOnExpiredTooltip'
                                    )}
                                  />
                                </StyledFieldColumn>
                              </StyledFieldRow>
                            ) : null}
                          </div>
                        </Tab>
                        {values.client && (
                          <Tab
                            hasFooter={false}
                            title={t(
                              'translation.AppointmentModal.label-client'
                            )}
                          >
                            {viewLocation?.bookingKind === 'GROUP' ? (
                              <ClientDetailsGroup
                                initialValues={values}
                                onEdit={getOnEditClient(form, values)}
                              />
                            ) : (
                              <ClientDetails
                                initialValues={values}
                                setActiveForm={setActiveForm}
                                isNew={isNewClient}
                                onEdit={getOnEditClient(form, values)}
                                mobile_onReminders={() => {
                                  setActiveForm('reminder_list')
                                }}
                                mobile_onGiftCards={() => {
                                  setActiveForm('giftCards_list')
                                }}
                                mobile_onProducts={() => {
                                  setActiveForm('products_list')
                                }}
                              />
                            )}
                          </Tab>
                        )}
                        {!isMobile && values.client && (
                          <Tab
                            title={t(
                              'translation.ClientReminders.reminders-label'
                            )}
                            hasFooter={false}
                          >
                            <AppointmentClientReminders
                              onAddNewReminder={getOnNewReminder(form)}
                              clientId={values?.client?.id}
                              org={org}
                              closeModal={handleOnCloseEvent}
                            />
                          </Tab>
                        )}
                        {values.client && values?.id && (
                          <Tab
                            title={t('translation.Appointment.privitci')}
                            hasFooter={false}
                          >
                            <AppointmentAttachments
                              initialValues={initialValues}
                              isLoading={false}
                              values={values}
                              booking={appointment}
                              appointment={{}}
                              setFieldValue={setFieldValue}
                              refetchAppointment={refetchAppointment}
                              closeModal={handleOnCloseEvent}
                              onSaveForm={() => {
                                activeForm === 'appointment' &&
                                Object.values(formRef.current?.errors || {})
                                  .length
                                  ? setSelectedTabIndex(0)
                                  : formRef.current?.submitForm()
                              }}
                            />
                          </Tab>
                        )}
                        {!isNew ? (
                          <Tab title={'Log'} hasFooter={false}>
                            <AppointmentChangelog
                              isActiveTab={
                                values?.client
                                  ? isMobile
                                    ? selectedTabIndex === 3
                                    : selectedTabIndex === 4
                                  : isMobile
                                  ? selectedTabIndex === 2
                                  : selectedTabIndex === 3
                              }
                              closeModal={handleOnCloseEvent}
                              org={getOrganizationData}
                              booking={appointment}
                              onCloseForm={handleOnCloseEvent}
                              onSaveForm={() => {
                                activeForm === 'appointment' &&
                                Object.values(formRef.current?.errors || {})
                                  .length
                                  ? setSelectedTabIndex(0)
                                  : formRef.current?.submitForm()
                              }}
                            />
                          </Tab>
                        ) : null}
                      </TabView>
                    </>
                  ) : activeForm === 'client' ? (
                    <ClientFormWrapper
                      values={values}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      loadingAddClient={loadingAddClient}
                      isNew={isNewClient}
                      setIsNewClient={setIsNewClient}
                      setActiveForm={setActiveForm}
                      onGoBack={() => {
                        setActiveForm('appointment')
                      }}
                      title={
                        !!appointment?.client
                          ? `${appointment?.client?.firstName} ${appointment?.client?.lastName}`
                          : `${values?.client?.firstName} ${values.client?.lastName}`
                      }
                      clientDetails={true}
                      isAppointment
                      refetchClient={async () => {
                        const result = await refetchAppointment()
                        setFieldValue(
                          'client',
                          result.data?.appointment?.find?.client
                        )
                      }}
                    />
                  ) : activeForm === 'service' ? (
                    <ServiceForm2
                      form={form}
                      isNew={true}
                      hasOnlineBooking={hasOnlineBooking}
                      isAppointment
                      onGoBack={() => setActiveForm('appointment')}
                    />
                  ) : activeForm === 'paymentNotification' ? (
                    <PaymentNotification
                      flattenedServices={flattenedServices}
                      complexBooking
                      totalServicesPrice={newTotalPrice}
                      location={viewLocation}
                      values={values}
                      isBusy={
                        loadingOrgData ||
                        loadingLocation ||
                        loadingStripeConnectAccountData
                      }
                      org={getOrganizationData}
                      hasPrepaymentsCredentials={hasPrepaymentsCredentials}
                      closeModal={handleOnCloseEvent}
                      stripeAccountInfo={
                        stripeConnectAccountData?.prepayments
                          ?.getStripeConnectAccountInfo
                      }
                      hasInvoicingData={hasInvoicingData}
                    />
                  ) : activeForm === 'copyAppointment' ? (
                    <CopyAppointment
                      event={values}
                      timeSlotDuration={viewLocation?.timeSlotDuration!}
                      setFieldValue={setFieldValue}
                      location={viewLocation}
                      flattenedServices={flattenedServices}
                      getDefaultAppointmentCopy={() =>
                        generateDefaultAppointmentCopies(values)[0]
                      }
                      isPrepaymentDisabled={isPrepaymentDisabled}
                    />
                  ) : activeForm === 'reminder' ? (
                    <AppointmentClientRemindersForm
                      isLoading={createReminderMutation.isLoading}
                    />
                  ) : // helper activeForm for displaying client reminders from appointment
                  activeForm === 'reminder_list' ? (
                    <AppointmentClientReminders
                      onAddNewReminder={getOnNewReminder(form)}
                      clientId={values?.client?.id}
                      org={org}
                      closeModal={handleOnCloseEvent}
                    />
                  ) : activeForm === 'giftCards_list' ? (
                    <ClientGiftCardsList
                      clientId={values?.client?.id}
                      closeModal={handleOnCloseEvent}
                      isAppointmentModal
                    />
                  ) : activeForm === 'products_list' ? (
                    <ClientProductsList
                      clientId={values?.client?.id}
                      closeModal={handleOnCloseEvent}
                      isAppointmentModal
                    />
                  ) : (
                    <UpdateStatusModal
                      appointmentStatus={appointmentStatus}
                      event={values}
                      setFieldValue={setFieldValue}
                      smsBalance={smsBalance}
                      smsBalanceLoading={smsBalanceLoading}
                    />
                  )
                }}
              </Form>
            )}
          </FormWrapper>
          {(selectedTabIndex === 2 && isMobile) ||
          (selectedTabIndex === 3 && isMobile) ? null : (
            <FormFooterContainer hasShadow>
              {selectedTabIndex === 0 &&
              !isMobile &&
              !isNew &&
              activeForm === 'appointment' &&
              org?.subscriptionPlan === 'advanced' &&
              appointment?.status !== 'DRAFT' &&
              canAccessInvoicing &&
              !isLoading ? (
                <Button
                  buttonType={'primary'}
                  type={'button'}
                  name={'issueReceipt'}
                  label={
                    appointment?.paymentStatus === 'PAID'
                      ? 'Ponovi izdavanje računa'
                      : 'Izdaj račun'
                  }
                  whiteLoader
                  isLoading={preparingForIssueReceipt}
                  footerButton
                  onClick={() => {
                    issueReceiptButtonDisabled
                      ? navigateTo.posScreen({ locationId, orgId })
                      : handleIssueReceipt(formRef?.current?.values)
                  }}
                  style={{
                    marginRight: 'auto',
                    paddingRight: preparingForIssueReceipt ? '60px' : undefined,
                  }}
                />
              ) : null}

              <Button
                buttonType={isMobile ? 'secondary' : 'text'}
                name={'close'}
                label={t('translation.AppointmentModal.button-close')}
                footerButton
                onClick={() => {
                  if (activeForm !== 'appointment') {
                    // for handling going back on mobile from new reminder screen
                    if (isMobile && activeForm === 'reminder') {
                      setActiveForm('reminder_list')
                    } else {
                      setActiveForm('appointment')
                    }
                  } else {
                    if (resetFormValues()) {
                      handleOnCloseEvent()
                    } else {
                      setTimeout(async () => {
                        formRef.current?.resetForm()
                        handleOnCloseEvent()
                      }, 100)
                    }
                  }
                }}
              />

              {canEditAppointment ? (
                <div className={`${isMobile ? 'flex-1' : 'self-end'}`}>
                  <Button
                    onClick={async () => {
                      const errors = await formRef.current?.validateForm()
                      setError(errors as any)
                      if (
                        activeForm === 'paymentNotification' &&
                        !hasPrepaymentsCredentials
                      )
                        navigateTo.locationPrepaymentSettings({
                          isStripeProvider:
                            getOrganizationData?.prepaymentsProvider ===
                            'STRIPE',
                        })
                      else if (errors?.hasOwnProperty('services')) {
                        setActiveForm('appointment')
                        setSelectedTabIndex(0)
                      } else {
                        activeForm === 'appointment' &&
                        Object.values(formRef.current?.errors || {}).length
                          ? setSelectedTabIndex(0)
                          : formRef.current?.submitForm()
                      }
                    }}
                    tooltip={
                      (activeForm === 'paymentNotification' &&
                        (org?.subscriptionPlan !== 'advanced' ||
                          !formRef.current?.values?.client?.email ||
                          !formRef.current?.values?.services?.find(
                            x => x?.service
                          ))) ||
                      (activeForm === 'appointment' && serviceMinDuration)
                        ? t(
                            'translation.AppointmentModal.save-button-disabled-tooltip'
                          )
                        : null
                    }
                    tooltipPosition="top"
                    buttonType={'primary'}
                    name={'saveChanges'}
                    disabled={
                      (activeForm === 'paymentNotification' &&
                        (org?.subscriptionPlan !== 'advanced' ||
                          !formRef.current?.values?.client?.email ||
                          !formRef.current?.values?.services?.find(
                            x => x?.service
                          ))) ||
                      (activeForm === 'appointment' && serviceMinDuration)
                    }
                    label={getSaveButtonLabel()}
                    className={`${isMobile ? 'w-full' : ''}`}
                  />
                </div>
              ) : null}
            </FormFooterContainer>
          )}
        </ModalWrapper>
      </Modal>
    </Container>
  )
}

const stagingAnimation = keyframes`
  0% {
    transform: scale(.5);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

export const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  ${HeaderContainer} {
    position: relative;
  }
`

export const Container = styled.div`
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  height: 100%;
  align-items: center;
  max-width: 640px;
  box-sizing: border-box;
  //width: 100%;
  z-index: 100000;
  flex-direction: column;
  top: 0px;
  left: 0px;
  overflow: auto;
  animation: 200ms ${fadeIn} ${easeOutQuart};

  @media ${device.tablet} {
    max-width: 100%;
    padding-top: 0px;
    z-index: 2147483643;
    position: fixed;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    ${TabContentContainer} {
      overflow-x: hidden;
    }
  }

  // to keep billing plans modal scrollable (and confirm/cancel buttons accessible)
  @media screen and (min-width: 1020px) and (max-height: 760px) {
    overflow-y: scroll;
  }
`

export const ClientWarningText = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  svg {
    width: 40px;
    height: 40px;
    margin-left: 12px;
    margin-right: 24px;

    @media ${device.tablet} {
      margin-left: 16px;
      margin-right: 30px;
      width: 50px;
      height: 50px;
    }
  }
  p {
    color: ${props => props.theme.colors.secondaryDark};
    font-weight: 300;
  }
`
export const Row = styled.div`
  padding-bottom: 15px;

  @media ${device.tablet} {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid ${props => props.theme.colors.outline};
  }
`

export const ClientWarningRow = styled.div`
  margin-bottom: 10px;

  @media ${device.tablet} {
    margin-top: 10px;
    margin-bottom: 0px;
  }
`

export const ClientCheckBoxRow = styled.div`
  margin-top: 10px;
  padding-bottom: 15px;
  input {
    width: 48px;
    height: 48px;
    margin-right: 2px;

    &:checked::before {
      width: 50px;
      height: 50px;
      top: -2px;
    }

    &:checked::after {
      top: 11px;
      left: 20px;
    }

    ::before {
      width: 50px;
      height: 50px;
      border: 1px solid #dbdfe2;
    }

    ::after {
      width: 15px;
      height: 25px;
      top: 6px;
      left: 20px;
    }
  }
`

export const StyledTextArea = styled.div`
  margin-top: 20px;
  @media ${device.tablet} {
    margin-top: 20px;
    padding-top: 20px;
    label span {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }
`
export const FormWrapper = styled.div`
  flex: 1;
  overflow: auto;
  background: white;

  form {
    height: 100%;
  }

  ${StyledFieldsContainer} {
    height: 100%;
  }

  ${StyledTabContainer} {
    height: 100%;
  }

  ${FormsContainer} {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`

export const Modal = styled.section`
  background-size: 100% auto;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  margin: 0 0 1rem;
  width: 640px;
  height: 100vh;
  overflow: inherit;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: inherit;
  flex-direction: column;
  flex-grow: 0;
  outline: none;
  right: 0;
  animation: 300ms ${stagingAnimation} ${easeOutQuart};

  @media ${device.tablet} {
    max-height: 100%;
    height: 100%;
    width: 100%;
    border-radius: 0;
    overflow: auto;

    ${FormFooterContainer} {
      background: ${props => props.theme.colors.light} !important;
      justify-content: space-around;
      padding-left: 15px;
      ${ButtonStyled} {
        height: 50px;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;

        :first-child {
          margin-right: 10px;
        }
      }
    }
  }

  // to keep billing plans modal scrollable (and confirm/cancel buttons accessible)
  @media screen and (min-width: 1020px) and (max-height: 760px) {
    overflow: unset;
  }
`

export const ServicesRow = styled(Row)`
  @media ${device.tablet} {
    padding-top: 10px;
  }
`

export const StyledFieldRow = styled(FieldRow)`
  margin-top: 10px;
  @media ${device.tablet} {
    margin-top: 5px;
    margin-bottom: 0px;
  }
`
export const StyledFieldColumn = styled(FieldColumn)`
  @media ${device.tablet} {
    margin-bottom: 0px;
  }
`
