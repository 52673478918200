import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useField } from 'formik'
import { useFormContext } from 'components/Form/Form'
import { FieldContainer } from 'components/Form/FieldContainer'

import ReactQuill from 'react-quill'
type TFormFieldRichTextEditorProps = {
  name: string
  val: any
  label?: string
  disabled?: boolean
  helpText?: string
  helpLink?: string
  onKeyPress?: (val: any) => any
}

export const FormFieldRichTextEditor = (
  props: TFormFieldRichTextEditorProps
) => {
  const { label, helpText, helpLink, val, onKeyPress, disabled } = props
  const [field, meta] = useField(props)
  const [isMounted, setIsMounted] = useState(false)

  //bug in react-quill grabs focus on mount, so we need to wait until it's mounted
  //https://github.com/zenoamaro/react-quill/issues/317
  useEffect(() => {
    setIsMounted(true)
  }, [])
  const formContext = useFormContext()
  const handleChange = useCallback(
    value => {
      formContext?.valueChange(field, '', value)
    },
    [formContext, field]
  )
  const editorContainerRef = useRef()

  return (
    <FieldContainer
      label={label}
      error={meta.error}
      fieldName={field.name}
      type="richText"
      helpText={helpText}
      helpLink={helpLink}
    >
      {/* @ts-ignore */}
      <div ref={editorContainerRef}>
        {isMounted ? (
          <ReactQuill
            theme="snow"
            className={disabled ? '!bg-[#f1f1f2]' : undefined}
            readOnly={disabled}
            value={val}
            onChange={handleChange}
            onKeyPress={onKeyPress}
            bounds={editorContainerRef?.current}
          />
        ) : null}
      </div>
    </FieldContainer>
  )
}
